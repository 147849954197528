import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Pane, Text, Button, Heading, Switch, TextInput } from 'evergreen-ui';
import { Mutation, Query } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Icon } from '@blueprintjs/core';
import * as Yup from 'yup';
import gql from 'graphql-tag';
import { SEND_TRANSACTION, REVIEW_INFO } from '../../graphql/transaction';

export const SenderInformation = ({ verifySender, occupations, originFounds }) => {
  const sfunds = (verifySender.sfunds || "6");
  const sfundsObj = originFounds.find(row => row.originId === sfunds);
  const occupationObj = occupations?.find(occu => occu.id === (verifySender.cindustry || "5"))?.jobs?.find(row => row.idjob === (verifySender.occupation || "83"));
  const ssn = localStorage.senderSSN !== undefined ? localStorage.senderSSN : verifySender.ssn;
  const ssnEncode = ssn ? `*******${ssn.substr(7)}` : "";
  return (
  <Pane
    elevation={1}
    width={300}
    height={250}
    padding={30}
    margin={10}
    display="flex"
    flexDirection="column"
  >
    <Heading size={500} paddingBottom={10}>
      SENDER INFORMATION
    </Heading>
    <Text size={300}>
      {`${verifySender.fname} ${verifySender.mname} ${verifySender.lname} ${verifySender.slname}`}
    </Text>
    <Text size={300}>{`Phone: ${verifySender.phone}`}</Text>
    <Text size={300}>{`Email: ${verifySender.email}`}</Text>
    <Text size={300}>{occupationObj && `Occupation: ${occupationObj.job}`}</Text>
    <Text size={300}>{sfundsObj && `Source of funds: ${sfundsObj.originDescription}`}</Text>
    <Text size={300}>{ssn && `SSN: ${ssnEncode}`}</Text>
  </Pane>
  )
};

export const RecipientInformation = ({ transaction }) => (
  <Pane
    elevation={1}
    width={300}
    minHeight={250}
    padding={30}
    margin={10}
    display="flex"
    flexDirection="column"
  >
    <Heading size={500} paddingBottom={10}>
      RECIPIENT INFORMATION
    </Heading>
    <Text size={300} flexWrap="wrap" wordBreak="break-word">
      {`${transaction.fnameRecipient} ${transaction.mnameRecipient} ${transaction.lnameRecipient} ${transaction.slnameRecipient}`}
    </Text>
    <Text size={300}>{`Phone: ${transaction.phoneRecipient}`}</Text>
    <Text
      size={300}
      flexWrap="wrap"
      wordBreak="break-word"
    >{`Address: ${transaction.addressRecipient}`}</Text>
    <Text size={300}>{`State: ${transaction.nameStateRecipient}`}</Text>
    <Text size={300}>{`City: ${transaction.nameCityRecipient}`}</Text>
    <Text size={300}>{`Country: ${transaction.nameCountryRecipient}`}</Text>
    <Text size={300}>{`Relationship to Sender: ${transaction.relationshipToSender}`}</Text>
    <Text size={300}>{`Intended use of funds: ${transaction.intendedUseOfFundsName}`}</Text>
    {transaction.numIdRecipient ? (<Text size={300}>{transaction.idCountryRecipient === 'BRA' ? `CPF`: transaction.typeIdRecipient}{`: ${transaction.numIdRecipient}`}</Text>) : null }
  </Pane>
);

export const DeliveryMethod = ({ transaction }) => (
  <Pane
    elevation={1}
    width={300}
    height={250}
    padding={30}
    margin={10}
    display="flex"
    // alignItems="center"
    flexDirection="column"
  >
    <Heading size={500} paddingBottom={10}>
      DELIVERY METHOD
    </Heading>
    <Text size={300}>Payment mode: {(() => {
      switch(transaction.modePayReceiver) {
        case 'P': return 'CASH PICKUP';                
        case 'C': return 'BANK DEPOSIT';
        case 'O': return 'MOBILE WALLET';
        case 'D': return 'HOME DELIVERY';
        default: return '';
      }
    })()}</Text>
    <Text size={300}>{`Payer: ${transaction.payerName}`}</Text>
    {transaction.modePayReceiver === 'C' ? (
      <Pane
        display="flex"
        flexDirection="column"
        // alignItems="center"
      >
        <Text size={300} flexWrap="wrap">
          {`Account Number: ${transaction.accountRecipient}`}
        </Text>

        <Text size={300}>
          {`Account Type: ${
            transaction.accountType === 'S' ? 'SAVINGS' : 'CHECKING'
          }`}
        </Text>
        {transaction.routingRecipient !== '' && (
          <Text size={300} flexWrap="wrap" wordBreak="break-word">
            {`${
              transaction.idCountryRecipient === 'IND'
                ? 'IFSC:'
                : 'Routing Number:'
            } ${transaction.routingRecipient}`}
          </Text>
        )}
      </Pane>
    ) : null}
    {transaction.modePayReceiver === 'O' ? (
      <Pane
        display="flex"
        flexDirection="column"
      >
        <Text size={300} flexWrap="wrap">
          {`Wallet Number: ${transaction.accountRecipient}`}
        </Text>
      </Pane>
    ) : null}
  </Pane>
);

export const TransactionDetails = ({ transaction, transactionCost }) => (
  <Pane
    elevation={1}
    backgroundColor="tint2"
    width={300}
    height={250}
    padding={30}
    margin={10}
    display="flex"
    flexDirection="column"
  >
    <Heading size={500} paddingBottom={10}>
      TRANSACTION DETAILS
    </Heading>
    <Text size={300}>{`Amount to send: $${transaction.amountToSend} USD`}</Text>
    <Text size={300}>{`Transfer fees: $${transactionCost.fees} USD`}</Text>
    <Text size={300}>{`Total Due: $ ${(Number(transaction.amountToSend) + Number(transactionCost.fees)).toFixed(2)} USD`}</Text>
    <Text size={300}>{`Exchange Rate: ${transactionCost.exchangeRate}`}</Text>
    <Text
      size={300}
    >{`Recipient gets: ${transactionCost.currencySymbol} ${transactionCost.amountToBePaid} ${transactionCost.currencyIsoCode}`}</Text>
  </Pane>
);

export const FormButtons = ({ history, loading, isDisabled }) => (
  <Pane display="flex" justifyContent="center" alignItems="center" padding={16}>
    <Button
      marginRight={5}
      height={50}
      width={110}
      onClick={() => history.goBack()}
      type="button"
      iconBefore="arrow-left"
    >
      Back
    </Button>
    <Button
      type="submit"
      appearance="primary"
      intent="success"
      width={230}
      height={50}
      marginLeft={5}
      textAlign="center"
      display="flex"
      justifyContent="center"
      alignItems="center"
      isLoading={loading}
      disabled={isDisabled}
    >
      {loading ? 'Processing' : 'SEND'}
    </Button>
  </Pane>
);

const ConfirmationForm = ({ history }) => {  
  const [isDisabled, setDisableSubmitBtn] = useState( JSON.parse(sessionStorage.getItem('disableSubmitBtn') || 'false') );
  return (
  <div className="confirmation-form-container">
    {/* sorry mom */}
    {window.scrollTo(0, 0)}
    <Pane
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Heading size={700} marginTop="default" textAlign="center">
        REVIEW & SEND
      </Heading>

      <Text marginBottom="20px" textAlign="center">
        Please review transaction details and make sure everything is correct
        before you click Send.
      </Text>
      <Query
        query={REVIEW_INFO}
        variables={{ jwt: sessionStorage.getItem('token') }}
      >
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error2! ${error.message}`;
          const { transaction, verifySender, transactionCost, occupation: occupations, originFounds } = data;
          const sfundsObj = originFounds.find(row => row.originId === (verifySender.sfunds || "6"));
          return (
            <Mutation
              mutation={SEND_TRANSACTION}
              update={(cache, { data: { sendMoney } }) => {
                const query = gql`
                  query Receipt {
                    receipt @client {
                      folio
                      fname
                      fullNameRecipient
                      amountToPaid
                      amountToSend
                      confirmed
                      dateAvaliable
                      transactionDate
                      resultMessage
                      currencySymbol
                      currencyIsoCode
                    }
                  }
                `;
                const previousState = cache.readQuery({ query });
                const newState = {
                  ...previousState,
                  receipt: {
                    ...previousState.receipt,
                    folio: sendMoney.folio,
                    fname: sendMoney.fname,
                    fullNameRecipient: sendMoney.fullNameRecipient,
                    amountToPaid: sendMoney.amountToPaid,
                    amountToSend: sendMoney.amountToSend,
                    confirmed: sendMoney.confirmed,
                    transactionDate: sendMoney.transactionDate,
                    dateAvaliable: sendMoney.dateAvaliable,
                    resultMessage: sendMoney.resultMessage,
                    currencySymbol: transactionCost.currencySymbol,
                    currencyIsoCode: transactionCost.currencyIsoCode
                  }
                };

                cache.writeData({ query, data: newState });
                return null;
              }}
            >
              {/* eslint-disable no-shadow */}
              {(sendMoney, { loading }) => {
                const occupationObj = occupations?.find(occu => occu.id === (verifySender.cindustry || "5"))?.jobs?.find(row => row.idjob === (verifySender.occupation || "83"));
                const showOriginFunds = true;
                const ssn = verifySender.ssn ? verifySender.ssn : localStorage.senderSSN;
                return (
                  <Formik
                    initialValues={{
                      comfirmOriginFunds: true,
                      ssnnone: !ssn,
                      showpassowrd: false,
                      ssn
                    }}
                    validationSchema={
                      Yup.lazy((values) => Yup.object().shape({
                        comfirmOriginFunds: showOriginFunds ? Yup.boolean()
                          .required('This field is required')
                          .oneOf([true], 'This field is required') : Yup.boolean(),
                        ssnnone: Yup.boolean(),
                        ssn: !values.ssnnone && ssn === undefined ? Yup.string().required('SSN is required').matches("^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$", "SSN format is invalid") : Yup.string(),
                      }))
                    }
                    onSubmit={(values) => {
                      setDisableSubmitBtn(true);
                      if (!sessionStorage.getItem("disableSubmitBtn"))
                      {
                        sessionStorage.setItem('disableSubmitBtn', true);
                        sendMoney({
                          variables: {
                            idSender: verifySender.idSender,
                            fnameRecipient: transaction.fnameRecipient,
                            mnameRecipient: transaction.mnameRecipient,
                            lnameRecipient: transaction.lnameRecipient,
                            slnameRecipient: transaction.slnameRecipient,
                            idCountryRecipient: transaction.idCountryRecipient,
                            idStateRecipient: transaction.idStateRecipient,
                            idCityRecipient: transaction.idCityRecipient,
                            idPayer: transaction.idPayer,
                            addressRecipient: transaction.addressRecipient,
                            phoneRecipient: transaction.phoneRecipient,
                            modePayReceiver: transaction.modePayReceiver,
                            modePayCurrency: transaction.modePayCurrency,
                            amountToSend: transaction.amountToSend,
                            idPayment: 'P',
                            token: verifySender._id,
                            totalCost: transactionCost.fees,
                            accountRecipient: transaction.accountRecipient, // user account
                            accountType: transaction.accountType || 'S', // account type id
                            intendedUseOfFunds: transaction.intendedUseOfFunds || '',
                            intendedUseOfFundsName: transaction.intendedUseOfFundsName || '',
                            routingRecipient: transaction.routingRecipient, // routing
                            relationshipToSender: transaction.relationshipToSender,
                            comfirmOriginFunds: values.comfirmOriginFunds,
                            countryIssue: verifySender.countryIssue,
                            stateIssue: verifySender.stateIssue,
                            ssn: values.ssn || "",
                            hasSSN: values.ssn ? "true" : "false",
                            occupationName: occupationObj ? occupationObj.job : "",
                            cname: verifySender.cname || "APOLLO EXPORT WAREHOUSE INC.",
                            caddress: verifySender.caddress || "6950 NW 77 COURT",
                            cphone: verifySender.cphone || "3055928790",
                            cindustry: verifySender.cindustry || "5",
                            sfunds: sfundsObj?.originDescription,
                            typeIdRecipient: transaction.typeIdRecipient ?? undefined,
                            numberIdRecipient: transaction.numIdRecipient ?? undefined,
                            idMainBranch: transaction?.idMainBranch || ''
                          }
                        }).then(() => {
                          history.push('/sendmoney/receipt');
                        });
                      }
                    }}
                  >
                    {({values, errors, setFieldValue, handleChange}) =>
                      (
                      <Form>
                        <Pane
                          display="flex"
                          justifyContent="center"
                          padding={16}
                          flexWrap="wrap"
                          minHeight={0}
                        >
                          <SenderInformation verifySender={verifySender} occupations={occupations} originFounds={originFounds} />
                          <RecipientInformation transaction={transaction} />
                          <DeliveryMethod transaction={transaction} />
                          <TransactionDetails
                            transaction={transaction}
                            transactionCost={transactionCost}
                          />
                        </Pane>

                        {ssn === undefined &&
                        <Pane
                          display="flex"
                          justifyContent="center"
                          padding={16}
                          flexWrap="wrap"
                          minHeight={0}
                        >
                          <div className="form-element-container">
                            <Text>SSN None</Text>
                            <Switch
                              name="ssnnone"
                              id="ssnnone"
                              checked={values.ssnnone}
                              onChange={() => {
                                setFieldValue('ssnnone', !values.ssnnone)
                                if (values.ssnnone) {
                                  setFieldValue("ssn", "");
                                }
                              }}
                            />
                          </div>
                          {!values.ssnnone && 
                            <div className="form-element-container">
                              <Text>
                                SSN <Icon icon="eye-open" size={14} color={values.showpassowrd ? "blue" : ""} onClick={() => setFieldValue('showpassowrd', !values.showpassowrd)} />
                              </Text>
                              <div>
                                <TextInput
                                  name="ssn"
                                  value={values.ssn}
                                  onChange={handleChange}
                                  placeholder="SSN"
                                  className="form-element"
                                  maxLength="50"
                                  type={values.showpassowrd ? "text" : "password"}
                                />
                              </div>
                              <Text size={300} color="#EC4C47">
                                {errors.ssn}
                              </Text>
                            </div>
                          }
                          </Pane>
                        }
                        <Pane
                          display="flex"
                          justifyContent="center"
                          padding={16}
                          flexWrap="wrap"
                          minHeight={0}
                        >
                          {showOriginFunds && (
                            <div className="form-element-container">
                              <label htmlFor="comfirmOriginFunds">
                                By clicking the SEND button, you confirm that the origin of funds is the same selected.
                              </label>
                              <Text size={300} color="#EC4C47">
                                {errors.comfirmOriginFunds}
                              </Text>
                            </div>
                          )}
                        </Pane>
                        <FormButtons
                          history={history}
                          loading={loading}
                          isDisabled={isDisabled}
                        />
                      </Form>
                    )}
                  </Formik>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    </Pane>
  </div>
)};

export default withRouter(ConfirmationForm);
