import React from 'react';
import { Query } from 'react-apollo';
import { Pane, Heading } from 'evergreen-ui';
import Logo from './vianex-logo.png';
import appLogo from './mycrewwallet-logo.png';
import VERIFY_SENDER from '../../graphql/verifySender';

const Header = () => (
  <div>
    <Pane
      width="100%"
      height="80px"
      background="tint2"
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      padding="40px"
    >
      <img src={appLogo} height="60" alt="App Logo" />
      <Query
        query={VERIFY_SENDER}
        variables={{ jwt: sessionStorage.getItem('token') || '' }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <Heading size={400} marginTop="default">
                LOADING...
              </Heading>
            );
          if (error)
            return (
              <Heading size={400} marginTop="default">
                `Error ${error.message}`
              </Heading>
            );
          return (
            <Heading size={400} marginTop="default" textAlign="center">
              Hello {data.verifySender.fname}
            </Heading>
          );
        }}
      </Query>
      <img src={Logo} alt="Logo" width="150" height="40" />
    </Pane>
  </div>
);

export default Header;
