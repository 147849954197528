import gql from 'graphql-tag';

const TRANSACTION_FRAGMENT = gql`
  fragment quickSend on Transaction {
    idCountryRecipient
    nameCountryRecipient
    modePayCurrency
    modePayCurrencyName
    modePayReceiver
    idPayer
    payerName
    amountToSend
    addressRecipient
    idStateRecipient
    nameStateRecipient
    idCityRecipient
    nameCityRecipient
  }
`;

const COUNTRIES_AND_COST = gql`
  query TransactionCost {     
    transactionCost @client {
      amountToSend
      amountToBePaid
      exchangeRate
      fees
      hasCalculated
      minMaxError
      payerLimit
      amountToPaidOutLimit
      currencyIsoCode
      currencySymbol
    }
    transaction @client {
      ...quickSend
    }
  }
  ${TRANSACTION_FRAGMENT}
`;

export const COUNTRY_INFO = gql`
  query Country {    
    intendedFounds {
        id
        value
    }
    transaction @client {
      fnameRecipient
      mnameRecipient
      lnameRecipient
      slnameRecipient
      phoneRecipient
      modePayReceiver
      idCityRecipient
      nameCityRecipient
      idStateRecipient
      nameStateRecipient
      addressRecipient
      accountRecipient
      accountType
      intendedUseOfFunds
      intendedUseOfFundsName
      routingRecipient
      relationshipToSender
      numIdRecipient
      additionalFields
    }
  }
`;

export default COUNTRIES_AND_COST;
