import React from 'react';
import { Pane, Text, Strong, Heading } from 'evergreen-ui';
import { Query } from 'react-apollo';
import GET_RECEIPT from '../../graphql/receipt';

export const ErrorComponent = ({ resultMessage }) => (
  <Pane
    display="flex"
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
  >
    <Heading size={700} marginTop="default">
    Error applying transaction!
    </Heading>

    <Text marginBottom="40px" marginTop="20px" textAlign="center">
      {resultMessage}
    </Text>
  </Pane>
);

export const ReceiptComponent = ({ receipt }) => (
  <Pane
    display="flex"
    justifyContent="center"
    flexDirection="column"
    alignItems="center"
  >
    <Heading size={700} marginTop="default">
      SUCCESS!
    </Heading>

    <Text marginBottom="20px" textAlign="center">
      You transfer has been initiated
    </Text>
    <Pane
      elevation={1}
      margin={24}
      maxWidth={600}
      padding={30}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Heading size={600} padding={10}>
        Password: {receipt.folio}
      </Heading>
      <Strong>{`Transaction Date: ${new Date(
        Number(receipt.transactionDate)
      ).toLocaleString()}`}</Strong>
      <Strong />
      <Strong>Recipient: {receipt.fullNameRecipient}</Strong>
      <Strong>Funds Available on: {receipt.dateAvaliable}</Strong>
      <Strong>Amount Sent: $ {receipt.amountToSend} USD</Strong>
      <Strong>Amount to be Received: {receipt.currencySymbol} {receipt.amountToPaid} {receipt.currencyIsoCode}</Strong>
      <Strong>Receipt: Sent to registered email</Strong>
      <Heading size={200} textAlign="center" padding={10}>
        {`IMPORTANT: If your recipient will pick up cash at our partners
                location, please send your recipient the password number above (also
                available on receipt and transaction history). They will need to
                provide the password number in order to collect the money`}
      </Heading>
      <Heading size={600} padding={10}>
        Thank you for choosing Vianex!
      </Heading>
      <Strong marginBottom="20px" textAlign="center" color="#f39036">
        You can now close the screen.
      </Strong>
    </Pane>
  </Pane>
);

export const SwitchComponent = ({ receipt }) => {
  if (!receipt || !receipt.confirmed)
    return (
      <ErrorComponent
        resultMessage={(receipt && receipt.resultMessage) || ''}
      />
    );
  return <ReceiptComponent receipt={receipt} />;
};

const ReceiptForm = () => (
  <div>
    <Pane
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Query query={GET_RECEIPT}>
        {({ data: { receipt } }) => 
          <SwitchComponent receipt={receipt} />
        }
      </Query>
    </Pane>
  </div>
);

export default ReceiptForm;
