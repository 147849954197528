import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import PhoneInput, { formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags'
import { TextInput, Button, Text, Switch } from 'evergreen-ui';
import { Alert } from 'evergreen-ui/commonjs/alert';
import { Paragraph } from 'evergreen-ui/commonjs/typography';
import { Icon } from '@blueprintjs/core';
import Spinner from 'evergreen-ui/commonjs/spinner/src/Spinner';
import * as Yup from "yup"
import SelectControl from '../selectControl';


const SenderDataForm = ({
  sender: {
    verifySender: {
      fname,
      mname,
      lname,
      slname,
      phone,
      address,
      token,
      birthdate,
      occupation: tempOccupation,
      sfunds,
      cindustry,
      ssn,
      countryIssue,
      email,
    },
    occupation: occupations,
    originFounds: funds
  },
  updateUser,
  loading
}) =>
{
  let autosubmit = false;
  let additionalfields = false;
  let submited = false;
  const occupation = tempOccupation || "83";
  const [showpassowrd, setShowpassword] = useState(false);

  const occupationList = occupations?.find(occu => occu.id === (cindustry || "5"))?.jobs?.map(row => 
    ({
      value: row.idjob,
      label: row.job
    })
  );
  const foundsList = funds.map(row => 
    ({
      value: row.originId,
      label: row.originDescription
    })
  );
  useEffect(() => {
    if (autosubmit && !submited) {
      autosubmit();
      submited = true;
    }
  }, [autosubmit]);

  return (
  <Formik
    initialValues={{
      fname,
      mname,
      lname,
      slname,
      phone,
      address,
      email,
      birthdate,
      occupation: occupationList?.find(data => data.value === (occupation ? occupation.toString() : "83")) || {
        label: "",
        value: ""
      },
      sfunds: foundsList.find(data => data.value === (sfunds ? sfunds.toString() : "6")) || {
        label: "",
        value: ""
      },
      ssnnone: false,
      ssn: ssn || "",
      countryIssue,
      token
    }}
    validationSchema={
      Yup.lazy((values) =>
        Yup.object().shape({
        email: Yup.string()
          .email('E-mail is not valid!')
          .required('E-mail is required!'),
        fname: Yup.string()
          .required('First Name is required')
          .matches(/^\w+( \w+)*$/, {
            message: 'Not a valid value!'
          })
          .matches(/^[a-zA-Z ]+$/, {
            message: 'Numbers are not allowed'
          }),
        mname: Yup.string()
          .matches(/^\w+( \w+)*$/, {
            message: 'Not a valid value!'
          })
          .matches(/^[a-zA-Z ]+$/, {
            message: 'Numbers are not allowed'
          }),
        lname: Yup.string()
          .required('Last Name is required')
          .matches(/^\w+( \w+)*$/, {
            message: 'Not a valid value!'
          })
          .matches(/^[a-zA-Z ]+$/, {
            message: 'Numbers are not allowed'
          }),
        slname: Yup.string()
          .matches(/^\w+( \w+)*$/, {
            message: 'Not a valid value!'
          })
          .matches(/^[a-zA-Z ]+$/, {
            message: 'Numbers are not allowed'
          }),
        phone: Yup.string()
          .required('Phone number is required')          
          .test({
            test(value, ctx) {
              if (value && !isValidPhoneNumber(value)) {
                return ctx.createError({ message: `Please enter a valid phone number` })
              }
              return true
            }
          }),          
        address: Yup.string(),
        birthdate: Yup.string()
          .required('Date of birth is required')
          .matches(/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/, 'Birthdate not valid'),
        ssnnone: Yup.boolean(),
        ssn: values.ssnnone ? Yup.string() : Yup.string().required('SSN is required').matches("^(?!666|000|9\\d{2})\\d{3}-(?!00)\\d{2}-(?!0{4})\\d{4}$", "SSN format is invalid"),

      }))
    }
    onSubmit={values => {
      // eslint-disable-next-line no-param-reassign
      values.phone = formatPhoneNumberIntl(values.phone);
      updateUser(values);
    }}
  >
    {({ values, handleChange, errors, setFieldValue, submitForm }) => {
      autosubmit = submitForm;
      autosubmit = false;
      additionalfields = false;
      if (autosubmit) {
        if (Object.keys(errors).length > 0) {
          return (
            <Alert intent="danger" 
              title="We weren't able to update your personal information"
            >
              {Object.keys(errors).map(error => (
                <Paragraph>{errors[error]}.</Paragraph>
              ))}
            </Alert>
          )
        }
        return (
          <Text><Spinner /> Loading...</Text>
        )
      }
      return (
      <Form>
        <div className="form-element-container">
          <Text>First Name</Text>
          <TextInput
            name="fname"
            value={values.fname}
            onChange={handleChange}
            placeholder="First Name"
            className="form-element"
            maxLength="50"
            disabled
          />
          <Text size={300} color="#EC4C47">
            {errors.fname}
          </Text>
        </div>
        <div className="form-element-container">
          <Text>Middle Name</Text>
          <TextInput
            name="mname"
            value={values.mname}
            onChange={handleChange}
            placeholder="Middle Name"
            className="form-element"
            maxLength="50"
            disabled
          />
          <Text size={300} color="#EC4C47">
            {errors.mname}
          </Text>
        </div>
        <div className="form-element-container">
          <Text>Last Name</Text>
          <TextInput
            name="lname"
            value={values.lname}
            onChange={handleChange}
            placeholder="Last Name"
            className="form-element"
            maxLength="50"
            disabled
          />
          <Text size={300} color="#EC4C47">
            {errors.lname}
          </Text>
        </div>
        <div className="form-element-container">
          <Text>Second Last Name</Text>
          <TextInput
            name="slname"
            value={values.slname}
            onChange={handleChange}
            placeholder="Second Last Name"
            className="form-element"
            maxLength="50"
            disabled
          />
          <Text size={300} color="#EC4C47">
            {errors.slname}
          </Text>
        </div>
        <div className="form-element-container">
          <Text>Phone Number</Text>
          <PhoneInput
            flags={flags}
            className= 'vdadd'
            label= 'phone'
            international
            value={values.phone}
            placeholder="Enter phone number"
            name='phone'
            onChange={phoneNumber => {
              if (phoneNumber) setFieldValue('phone', phoneNumber);
              else setFieldValue('phone', '');
            }}
          />
          <Text size={300} color="#EC4C47">
            {errors.phone}
          </Text>
        </div>
        <div className="form-element-container">
          <Text>Email</Text>
          <TextInput
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Email"
          />
          <Text size={300} color="#EC4C47">
            {errors.email}
          </Text>
        </div>
        <div className="form-element-container" data-test-id="dob-input">
          <Text>Date of birth</Text>
          <TextInput
            value={values.birthdate}
            onChange={handleChange}
            className="form-element"                         
            disabled
          />
          <Text size={300} color="#EC4C47">
            {errors.birthdate}
          </Text>
        </div>
        {additionalfields &&
          <>
            <div className="form-element-container">
              <div style={{width: 280}}>
                <SelectControl
                  controlTitle="Occupation"
                  data={occupationList}
                  onChange={selected => {
                    if (selected) setFieldValue('occupation', selected);
                    else setFieldValue('occupation', '');
                  }}
                  selectedItem={values.occupation}
                  error={errors.occupation}
                  defaultLabel="Select the Occupation"
                  name="occupation"
                />
                <Text size={300} color="#EC4C47">
                  {errors.occupation}
                </Text>
              </div>
            </div>
            <div className="form-element-container">
              <div style={{width: 280}}>
                <SelectControl
                  controlTitle="Source of Funds"
                  data={foundsList}
                  onChange={selected => {
                    if (selected) setFieldValue('sfunds', selected);
                    else setFieldValue('sfunds', '');
                  }}
                  selectedItem={values.sfunds}
                  error={errors.sfunds}
                  defaultLabel="Select the Source of Funds"
                  name="sfunds"
                />
              </div>
              <Text size={300} color="#EC4C47">
                {errors.sfunds}
              </Text>
            </div>
            <div className="form-element-container" data-test-id="dob-input">
              <Text>Country of Issue</Text>
              <TextInput
                value={values.countryIssue}
                onChange={handleChange}
                className="form-element"                         
                disabled
              />
                      
              <Text size={300} color="#EC4C47">
                {errors.countryIssue}
              </Text>
            </div>
          </>
        }
        <div className="form-element-container">
          <Text>SSN None</Text>
          <Switch
            name="ssnnone"
            id="ssnnone"
            checked={values.ssnnone}
            onChange={() => {
              setFieldValue('ssnnone', !values.ssnnone)
              if (values.ssnnone) {
                setFieldValue("ssn", "");
              }
            }}
          />
        </div>
        {!values.ssnnone && 
          <div className="form-element-container">
            <Text>
              SSN <Icon icon="eye-open" size={14} color={showpassowrd ? "blue" : ""} onClick={() => setShowpassword(!showpassowrd)} />
            </Text>
            <div>
              <TextInput
                name="ssn"
                value={values.ssn}
                onChange={handleChange}
                placeholder="SSN"
                className="form-element"
                maxLength="50"
                type={showpassowrd ? "text" : "password"}
              />
            </div>
            <Text size={300} color="#EC4C47">
              {errors.ssn}
            </Text>
          </div>
        }
        <div className="form-submit-btn-container">
          <Button
            type="submit"
            className="form-submit-btn"
            appearance="primary"
            isLoading={loading}
          >
            {loading ? 'SAVING' : 'CONFIRM AND CONTINUE'}
          </Button>
        </div>
      </Form>
    )}}
  </Formik>
)}



export default SenderDataForm;
