import gql from 'graphql-tag';

const VERIFY_SENDER_BASIC_FRAGMENT = gql`
  fragment basicData on Sender {
    _id
    idSender
    fname
    ssn
    birthdate
    sfunds
    address
    uuId
    __typename
  }
`;

const VERIFY_SENDER_EXTENDED_FRAGMENT = gql`
  fragment additionalData on Sender {
    lname
    mname
    slname
    phone
    birthdate
    email
    ssn
    sfunds
    occupation
    hasSSN
    cname
    caddress
    cphone
    token
  }
`;

const RECENT_TRANSACTIONS_FRAGMENT = gql`
  fragment recentTransactions on History {
    _id
    idRecipient
    transactionDate
    fullNameRecipient
    fnameRecipient
    mnameRecipient
    lnameRecipient
    slnameRecipient
    phoneRecipient
    idFlagReceiver
    transactionDate
    folio
    idFlagReceiverName
    idCountryRecipient
    nameCountryRecipient
    modePayCurrency
    modePayCurrencyName
    modePayReceiver
    idPayer
    namePayer
    idCityRecipient
    nameCityRecipient
    idStateRecipient
    nameStateRecipient
    addressRecipient
    accountRecipient
    accountType
    routingRecipient
    __typename
    idTypeId
    numberId
    additionalFields {
    ...additionalFieldsMap
  }
}

fragment additionalFieldsMap on AdditionalField {
  countryId
  deliveryMode
  entity
  field
  fieldLabel
  payerCode
  value
  __typename  
}

`;

const VERIFY_SENDER = gql`
  query verifySender($jwt: String!) {
    verifySender(jwt: $jwt) {
      ...basicData
    }
  }
  ${VERIFY_SENDER_BASIC_FRAGMENT}
`;

export const VERIFY_SENDER_EXTENDED = gql`
  query verifySender($jwt: String!) {
    verifySender(jwt: $jwt) {
      ...basicData
      ...additionalData
    }
    occupation {
      id
      industryName
      order
      jobs {
        job
        idjob
      }
    }
    originFounds {
      originId
      originDescription
      order
    }
  }
  ${VERIFY_SENDER_BASIC_FRAGMENT}
  ${VERIFY_SENDER_EXTENDED_FRAGMENT}
`;

export const VERIFY_SENDER_TRANSACTIONS = gql`
  query verifySender($jwt: String!) {
    verifySender(jwt: $jwt) {
      ...basicData
      transactions {
        ...recentTransactions
      }
    }
  }
  ${VERIFY_SENDER_BASIC_FRAGMENT}
  ${RECENT_TRANSACTIONS_FRAGMENT}
`;

export default VERIFY_SENDER;
