import React from 'react';
import { Heading, Pane, Text } from 'evergreen-ui';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { string, number, object, lazy } from 'yup';
import { withRouter } from 'react-router-dom';
import DestinationRatesForm from './destinationRatesForm';
import COUNTRIES_AND_COST from '../../graphql/countries';
import CALCULATE_COST from '../../graphql/calculateCost';

const DestinationRates = ({ history }) => {
  const MAX_AMOUNT_TO_SEND = 8000;
  const validationSchema = lazy(values => object().shape({
    amount: number()
      .typeError('Please enter a valid number')
      .min(1, 'Please enter a value greater than 0')
      .max(values.dolarLimitAmount === 0 ? MAX_AMOUNT_TO_SEND - 1 : values.dolarLimitAmount - 1, `Please enter a lower amount. The value must be less than: ${values.dolarLimitAmount === 0 ? MAX_AMOUNT_TO_SEND : values.dolarLimitAmount} USD`)
      .required('Please enter a valid amount'),
    location: string().required('Please search a destination city and country'),
    selectedCurrency: object().shape({
      label: string().required('Please select a currency')
    }),
    selectedPaymentMethod: object().shape({
      label: string().required('Please select a payment method')
    }),
    selectedPayer: object().shape({
      label: string().required('Please select a payer')
    })
  }));
  return (
    <Pane
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Heading size={700} marginTop="default">
        PAYOUT INSTRUCTIONS
      </Heading>
      <Text marginBottom="20px" textAlign="center">
        How much would you like to send and how would you like the money be
        received?
      </Text>

      <Query query={COUNTRIES_AND_COST}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;
          return (
            <div>
              <Mutation
                mutation={CALCULATE_COST}
                update={(cache, dt) => {
                  const query = gql`
                    query transactionCost {
                      transactionCost @client {
                        amountToBePaid
                        exchangeRate
                        amountToSend
                        minMaxError
                        fees
                        payerLimit
                        amountToPaidOutLimit
                        currencyIsoCode
                        currencySymbol
                      }
                    }
                  `;

                  const previousState = cache.readQuery({ query });                  
                  const {
                    data: { priceQuote }
                  } = dt;
                  const amt = parseFloat(priceQuote.amountToSend);
                  const newData = {
                    ...previousState,
                    transactionCost: {
                      ...previousState.transactionCost,
                      currencyIsoCode: priceQuote.currencyIsoCode,
                      currencySymbol: priceQuote.currencySymbol,
                      amountToBePaid: priceQuote.amountToPaid,
                      amountToSend: priceQuote.amountToSend,
                      exchangeRate: priceQuote.currentRate,
                      fees: priceQuote.feesByFunding[0].totalCost,
                      minMaxError: !!(
                        amt > parseFloat(priceQuote.maxAmount) ||
                        amt < parseFloat(priceQuote.minAmount)
                      ),
                      hasCalculated: !(
                        amt > parseFloat(priceQuote.maxAmount) ||
                        amt < parseFloat(priceQuote.minAmount)
                      ),
                      payerLimit: priceQuote.payerLimit,
                      amountToPaidOutLimit: priceQuote.amountToPaidOutLimit
                    }
                  };
                  cache.writeData({ query, data: newData });
                }}
              >
                {(destinationCost, info) => (
                  <DestinationRatesForm
                    validationSchema={validationSchema}
                    data={data}
                    defaultData={data.transaction}
                    onSubmit={values => {
                      destinationCost({
                        variables: {
                          addressRecipient: values.location,
                          idPayer: values.selectedPayer.value,
                          modePayCurrency: values.selectedCurrency.value,
                          modePayReceiver: values.selectedPaymentMethod.value,
                          idCountry: values.selectedCountry.value,
                          payerName: values.selectedPayer.label,
                          modePayCurrencyName: values.selectedCurrency.label,
                          modePayReceiverName:
                            values.selectedPaymentMethod.label,
                          nameCountryRecipient: values.selectedCountry.label,
                          amountToSend: parseFloat(values.amount),
                          idStateRecipient: values.idStateRecipient,
                          idCityRecipient: values.idCityRecipient,
                          nameStateRecipient: values.nameStateRecipient,
                          nameCityRecipient: values.nameCityRecipient,
                          idMainBranch: values.idMainBranch,
                          accNumberRegex: values.accNumberRegex,
                          accountDescriptionEN: values.accountDescriptionEN
                        }
                      });
                    }}
                    isLoading={info.loading}
                    onNext={() => history.push('/sendmoney/recipient')}
                  />
                )}
              </Mutation>
            </div>
          );
        }}
      </Query>
    </Pane>
  );
};

export default withRouter(DestinationRates);
