import gql from 'graphql-tag';

const CALCULATE_COST = gql`
  mutation destinationCost(
    $addressRecipient: String!    
    $idPayer: String!
    $modePayCurrency: String!
    $modePayReceiver: String!
    $idCountry: String!
    $amountToSend: Float!
    $payerName: String
    $modePayCurrencyName: String
    $modePayReceiverName: String
    $nameCountryRecipient: String
    $idStateRecipient: String!
    $idCityRecipient: String!
    $nameStateRecipient: String!
    $nameCityRecipient: String!
    $idMainBranch: String
    $accNumberRegex: String
    $accountDescriptionEN: String
  ) {
    priceQuote(
      idPayer: $idPayer
      modePayCurrency: $modePayCurrency
      modePayReceiver: $modePayReceiver
      idCountry: $idCountry
      amountToSend: $amountToSend
      idMainBranch: $idMainBranch
    ) {
      currentRate
      amountToPaid
      amountToSend
      minAmount
      maxAmount
      feesByFunding {
        totalCost
      }
      payerLimit
      amountToPaidOutLimit
      currencyIsoCode
      currencySymbol
    }
    updateDestination(
      addressRecipient: $addressRecipient      
      idPayer: $idPayer
      modePayCurrency: $modePayCurrency
      modePayReceiver: $modePayReceiver
      idCountry: $idCountry
      amountToSend: $amountToSend
      payerName: $payerName
      modePayCurrencyName: $modePayCurrencyName
      modePayReceiverName: $modePayReceiverName
      nameCountryRecipient: $nameCountryRecipient
      idStateRecipient: $idStateRecipient
      nameStateRecipient: $nameStateRecipient
      idCityRecipient: $idCityRecipient
      nameCityRecipient: $nameCityRecipient
      idMainBranch: $idMainBranch
      accNumberRegex: $accNumberRegex
      accountDescriptionEN: $accountDescriptionEN
    ) @client
  }
`;

export default CALCULATE_COST;
