/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-console */
import React, {useState} from 'react';
import PayersDynamicFields  from "@viamericas/viam-payers-dynamic-fields";
import { Formik, Form } from 'formik';
import { TextInput, Button, Text } from 'evergreen-ui';
import './style.css';
import SelectControl from '../selectControl';

const ENV = process.env;
const RecipientInfoForm = props => {
  const {
    validationSchema,
    data: { intendedFounds },
    modePayReceiver,
    onSubmit,
    defaultData,
    backButton,
    idMainBranch,
    idCountryRecipient
  } = props;
  
  const [defaultAdditionalFields, setAdditionalFields] = useState(JSON.parse(defaultData.additionalFields) || []);
  
  let submitDynamicFields = null;
  function bindDynamicFields(e) {
    submitDynamicFields = e;
  }

  const accountTypeList = [
    { label: 'CHECKING', value: 'C' },
    { label: 'SAVINGS', value: 'S' }
  ];

  const intendedUseOfFundsList = intendedFounds?.map(row => ({
    label: row.value,
    value: row.id
  }));

  const ux = {
    errorMessageClass: "errorMessage",
    successMessageClass: "successMessage",
    inputClass: "form-element-additional-field",
    inputClassError: "form-control is-invalid",
    componentDisabled: "disabled",
    className: "form-fields-container",
    // classNameForm_control_invalid: "form-control is-invalid",
    uxLocationSearchInput: {
      className: "form-control",
      classNameSearchApi: "search-api-test",
      classNameSearchBarContainer: "search-bar-container",
      classNameSearchInputContainer: "search-input-container",
      classNameSearchInput: "search-input",
      classNameSearchButton: "search-button",
      classNameAutoCompleteContainer: "autocomplete-container",
      classNameSuggestionItem: "suggestion-item",
      classNameLoading: "fa fa-spinner fa-pulse fa-3x fa-fw spinner"
    }
  }; 
  return (
    <Formik
      initialValues={{
        idStateRecipient: {
          label: defaultData.nameStateRecipient,
          value: defaultData.idStateRecipient
        },
        idCityRecipient: {
          label: defaultData.nameCityRecipient,
          value: defaultData.idCityRecipient
        },
        accountType: {
          label:
            defaultData.accountType === ''
              ? ''
              : accountTypeList?.find(o => o.value === defaultData?.accountType)
                  .label,
          value: defaultData.accountType
        },
        fnameRecipient: defaultData.fnameRecipient,
        mnameRecipient: defaultData.mnameRecipient,
        lnameRecipient: defaultData.lnameRecipient,
        slnameRecipient: defaultData.slnameRecipient,
        addressRecipient: defaultData.addressRecipient,
        accountRecipient: defaultData.accountRecipient,
        routingRecipient: String(defaultData.routingRecipient).trim(),
        phoneRecipient: defaultData.phoneRecipient,
        relationshipToSender: defaultData.relationshipToSender,
        numIdRecipient: defaultData.numIdRecipient,
        intendedUseOfFunds: {
          label:
            !defaultData.intendedUseOfFunds
              ? ''
              : intendedUseOfFundsList?.find(o => o.value === defaultData?.intendedUseOfFunds)
                  .label,
          value: defaultData.intendedUseOfFunds
        },
        additionalFields: defaultAdditionalFields
      }}
      onSubmit={async values => {
        const resDynamicsFields = await submitDynamicFields();
        if (resDynamicsFields.isSuccess) {
          const {additionalFields} = resDynamicsFields;
          if(additionalFields.length > 0) {
            sessionStorage.setItem('additionalFields', JSON.stringify(additionalFields));
          }
          
          const dataToSend = {
            ...values,
            "additionalFields": additionalFields
          };
          onSubmit(dataToSend);
        }
      }}
      validationSchema={validationSchema}
    >
      {({ values, handleChange, errors, setFieldValue }) => 
      {
        console.log("Values in Recipient info form", values);
        console.log("Erros", errors);

        const handleChangeDynamicFields = (e) => {
          console.log("additional fields", e);
          setAdditionalFields(e);
          setFieldValue('additionalFields', e);
        };
        return (
        <>
        <div className="main-recipient-form container" data-testid="reciever-Form">
          <Form id="form1">
            <div className="form-fields-container">
              <div className="form-element-container">
                <Text>First Name</Text>
                <TextInput
                  name="fnameRecipient"
                  placeholder="First Name"
                  onChange={handleChange}
                  value={values.fnameRecipient}
                  maxLength="50"
                />
                <Text size={300} color="#EC4C47">
                  {errors.fnameRecipient}
                </Text>
              </div>
              <div className="form-element-container">
                <Text>Middle Name</Text>
                <TextInput
                  name="mnameRecipient"
                  placeholder="Last Name"
                  onChange={handleChange}
                  value={values.mnameRecipient}
                  maxLength="50"
                />
                <Text size={300} color="#EC4C47">
                  {errors.mnameRecipient}
                </Text>
              </div>

              <div className="form-element-container">
                <Text>Last Name</Text>
                <TextInput
                  name="lnameRecipient"
                  placeholder="Last Name"
                  onChange={handleChange}
                  value={values.lnameRecipient}
                  maxLength="50"
                />
                <Text size={300} color="#EC4C47">
                  {errors.lnameRecipient}
                </Text>
              </div>

              <div className="form-element-container">
                <Text>Second Last Name</Text>
                <TextInput
                  name="slnameRecipient"
                  placeholder="Last Name"
                  onChange={handleChange}
                  value={values.slnameRecipient}
                  maxLength="50"
                />
                <Text size={300} color="#EC4C47">
                  {errors.slnameRecipient}
                </Text>
              </div>

              <div className="form-element-container">
                <Text>Phone Number</Text>
                <TextInput
                  name="phoneRecipient"
                  placeholder="Mobile Phone Number"
                  onChange={handleChange}
                  value={values.phoneRecipient}
                />
                <Text size={300} color="#EC4C47">
                  {errors.phoneRecipient}
                </Text>
              </div>
              <>
              </>
              <div className="form-element-container">
                <Text>Address</Text>
                <TextInput
                  name="addressRecipient"
                  placeholder="Address"
                  onChange={handleChange}
                  value={values.addressRecipient}
                  maxLength="60"
                  disabled
                  width={580}
                />
                <Text size={300} color="#EC4C47">
                  {errors.addressRecipient}
                </Text>
              </div>
              <div className="form-element-container">
                <Text>Relationship to Sender</Text>
                <TextInput
                  name="relationshipToSender"
                  placeholder="Relationship"
                  onChange={handleChange}
                  value={values.relationshipToSender}
                />
                <Text size={300} color="#EC4C47">
                  {errors.relationshipToSender}
                </Text>
              </div>
              <div className="form-element-container">
                <SelectControl
                  controlTitle="Intended use of funds"
                  data={intendedUseOfFundsList}
                  onChange={selected => {
                    if (selected) setFieldValue('intendedUseOfFunds', selected);
                    else setFieldValue('intendedUseOfFunds', '');
                  }}
                  selectedItem={values.intendedUseOfFunds}
                  error={errors.intendedUseOfFunds}
                  defaultLabel="Select Intended use of funds"
                  name="intendedUseOfFunds"
                />
              </div>
              {modePayReceiver === 'C' ? (
                <>
                  <div className="form-element-container">
                    <SelectControl
                      controlTitle="Account Type"
                      data={accountTypeList}
                      onChange={selected => {
                        if (selected) setFieldValue('accountType', selected);
                        else setFieldValue('accountType', '');
                      }}
                      selectedItem={values.accountType}
                      error={errors.accountType}
                      defaultLabel="Select Account Type"
                      name="accountType"
                    />
                  </div>
                  <div className="form-element-container">
                    <Text>Account Number</Text>
                    <TextInput
                      name="accountRecipient"
                      placeholder="Address"
                      onChange={handleChange}
                      value={values.accountRecipient}
                    />
                    <Text size={300} color="#EC4C47">
                      {errors.accountRecipient}
                    </Text>
                  </div>
                  
                </>
              ) : null}
              
              
            </div>
          </Form>
          <PayersDynamicFields
            SERVER_URL={ENV.REACT_APP_GRAPHQL_SERVER.replace('/graphql', '/payers/rules')}
            token={sessionStorage.getItem('token')}
            idCountry={idCountryRecipient}
            idMainBranch={idMainBranch}
            modoPago={modePayReceiver}
            idBranch="A00043"
            language="en"
            languageURL=""
            handleChange={handleChangeDynamicFields}
            fieldsRequired={(fields) => console.log("Fields Required", fields)}
            ux={ux}
            bindSubmitForm={bindDynamicFields}
            handleError={ er => console.error(er) }
            defaultValues={JSON.parse(sessionStorage.getItem('additionalFields')) || defaultAdditionalFields}
          />
          <div className="form-submit-btn-container">
            <Button
              margin={10}
              onClick={backButton}
              type="button"
              iconBefore="arrow-left"
            >
              Back
            </Button>
            <Button margin={10} type="submit" form="form1" iconAfter="arrow-right">
              Next
            </Button>
          </div>
        </div>
        </>
      )}}
    </Formik>
  );
};

export default RecipientInfoForm;
