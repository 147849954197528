import React from 'react';
import { SelectMenu, Button, Text, Pane } from 'evergreen-ui';

const SelectControl = ({
  data,
  onChange,
  selectedItem,
  error,
  defaultLabel,
  controlTitle,
  useClass = 'w_280px',
  width = 280,
  disabled = false
}) => (
  <Pane display="flex" flexDirection="column">
    <Pane>
      <Text data-test-id="select-title">{controlTitle}</Text>
      <SelectMenu
        title="Select name"
        options={data}
        selected={selectedItem.value}
        onSelect={onChange}
        width={width}
        disabled={disabled}
      >
        <Button
          iconAfter="caret-down"
          minWidth={200}
          display="flex"
          justifyContent="space-between"
          type="button"
          className={useClass}
          disabled={disabled}
        >
          {selectedItem.label || defaultLabel}
        </Button>
      </SelectMenu>
    </Pane>
    {error ? (
      <Text size={300} color="#EC4C47" data-test-id="error-text">
        {error.label}
      </Text>
    ) : null}
  </Pane>
);

export default SelectControl;
