import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { Query, Mutation } from 'react-apollo';
import { Button, Pane, Heading, Text } from 'evergreen-ui';
import { withRouter } from 'react-router-dom';

import { VERIFY_SENDER_TRANSACTIONS } from '../../graphql/verifySender';
import { QUICK_SEND } from '../../graphql/transaction';

import './style.css';

const NoHistoryMessage = () => (
  <Heading textAlign="center" padding={20}>
    You don’t have any recent transactions!
  </Heading>
);

const SenderList = ({history}) => {
  const columns = [
    {
      Header: 'RECENT RECIPIENTS',
      columns: [
        {
          Header: 'Recipient',
          accessor: 'fullNameRecipient',
          width: 320,
          
        },
        {
          Header: 'Country',
          accessor: 'nameCountryRecipient'
        },
        {
          Header: 'Payer',
          accessor: 'namePayer'
        },
        {
          Header: 'Last Transaction Date',
          accessor: 'transactionDate',
          width: 200
        },
        {
          Header: 'Last Folio #',
          accessor: 'folio'
        },
        {
          Header: 'Status of Last Transaction',
          accessor: 'idFlagReceiverName'
        }
      ]
    }
  ];

  return (
    <Pane
      justifyContent="center"
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <div className="sender-list-container">
        <Heading size={700} marginTop="default" marginBottom="default">
          YOUR RECENT RECIPIENTS
        </Heading>
        <Text textAlign="center" paddingBottom={10}>
          Send to an existing recipient or check status of your last
          transaction.
        </Text>
        <div className="sender-list-table-container">
          <Query
            query={VERIFY_SENDER_TRANSACTIONS}
            variables={{ jwt: sessionStorage.getItem('token') }}
          >
            {({ loading, error, data }) => {
              if (loading) return 'Loading...';
              if (error) return `Error ${error.message}`;
              if (data.verifySender.transactions.length === 0)
                return (
                  <div>
                    <NoHistoryMessage />
                  </div>
                );
              return (
                <Mutation mutation={QUICK_SEND}>
                  {quickSend => (
                    <div>
                      <ReactTable
                        data={data.verifySender.transactions}
                        columns={columns}
                        defaultPageSize={5}                                       
                        showPagination={false}

                        getTdProps={(state, rowInfo) => (
                          {
                            onClick: (e, handleOriginal) => {
                              sessionStorage.removeItem('additionalFields');
                              if (rowInfo) {
                                const { original } = rowInfo;
                                quickSend({
                                  variables: {
                                    idRecipient: original.idRecipient,
                                    idCountryRecipient:
                                      original.idCountryRecipient,
                                    nameCountryRecipient:
                                      original.nameCountryRecipient,
                                    modePayCurrency: original.modePayCurrency,
                                    modePayCurrencyName:
                                      original.modePayCurrencyName,
                                    modePayReceiver: original.modePayReceiver,
                                    idPayer: original.idPayer,
                                    payerName: original.namePayer,
                                    fnameRecipient: original.fnameRecipient,
                                    mnameRecipient: original.mnameRecipient,
                                    lnameRecipient: original.lnameRecipient,
                                    slnameRecipient: original.slnameRecipient,
                                    phoneRecipient: original.phoneRecipient,
                                    idCityRecipient: original.idCityRecipient,
                                    nameCityRecipient:
                                      original.nameCityRecipient,
                                    idStateRecipient: original.idStateRecipient,
                                    nameStateRecipient:
                                      original.nameStateRecipient,
                                    addressRecipient: original.addressRecipient,
                                    accountRecipient: original.accountRecipient,
                                    accountType: original.accountType,
                                    intendedUseOfFunds: original.intendedUseOfFunds,
                                    intendedUseOfFundsName: original.intendedUseOfFundsName,
                                    routingRecipient: original.routingRecipient,
                                    relationshipToSender: original.relationshipToSender,
                                    amountToSend: '',
                                    numIdRecipient: original.numberId ||  undefined,
                                    additionalFields: JSON.stringify(original.additionalFields)
                                  }
                                }).then(() =>
                                  history.push('/sendmoney/destination')
                                );
                              }
                              if (handleOriginal) {
                                handleOriginal();
                              }
                            }
                          }

                        )}
                      />
                      <Heading
                        size={700}
                        marginTop="default"
                        marginBottom="default"
                        textAlign="center"
                      >
                        OR
                      </Heading>
                    </div>
                  )}
                </Mutation>
              );
            }}
          </Query>
        </div>

        <Mutation mutation={QUICK_SEND}>
          {quickSend => (
            <Button
              onClick={() => {
                sessionStorage.removeItem('additionalFields');
                quickSend({
                  variables: {
                    idRecipient: '',
                    idCountryRecipient: '',
                    nameCountryRecipient: '',
                    modePayCurrency: '',
                    modePayCurrencyName: '',
                    modePayReceiver: '',
                    idPayer: '',
                    payerName: '',
                    fnameRecipient: '',
                    mnameRecipient: '',
                    lnameRecipient: '',
                    slnameRecipient: '',
                    phoneRecipient: '',
                    idCityRecipient: '',
                    nameCityRecipient: '',
                    idStateRecipient: '',
                    nameStateRecipient: '',
                    addressRecipient: '',
                    accountRecipient: '',
                    accountType: '',
                    intendedUseOfFunds: '',
                    intendedUseOfFundsName: '',
                    routingRecipient: '',
                    relationshipToSender: '',
                    amountToSend: '',
                    numIdRecipient: undefined,
                    additionalFields: null
                  }
                }).then(() => history.push('/sendmoney/destination'))
              }}
              appearance="primary"
              intent="success"
            >
              Create New Recipient
            </Button>
          )}
        </Mutation>
      </div>
    </Pane>
  );
};

export default withRouter(SenderList);
