import gql from 'graphql-tag';

const CREATE_SENDER = gql`
  mutation createSender(
    $fname: String!
    $mname: String
    $lname: String!
    $slname: String
    $phone: String!
    $email: String!
    $birthdate: String!
    $ssn: String!
    $token: String!
  ) {
    createSender(
      fname: $fname
      mname: $mname
      lname: $lname
      slname: $slname
      phone: $phone
      email: $email
      birthdate: $birthdate
      ssn: $ssn
      token: $token
    ) {
      _id
      idSender
    }
  }
`;

export const QUICK_SEND = gql`
  mutation quickSend(
    $idRecipient: String
    $idCountryRecipient: String
    $nameCountryRecipient: String
    $modePayCurrency: String
    $modePayCurrencyName: String
    $modePayReceiver: String
    $idPayer: String
    $payerName: String
    $fnameRecipient: String
    $mnameRecipient: String
    $lnameRecipient: String
    $slnameRecipient: String
    $phoneRecipient: String
    $idCityRecipient: String
    $nameCityRecipient: String
    $idStateRecipient: String
    $nameStateRecipient: String
    $addressRecipient: String
    $accountRecipient: String
    $accountType: String
    $intendedUseOfFunds: String
    $intendedUseOfFundsName: String
    $routingRecipient: String
    $relationshipToSender: String
    $amountToSend: String
    $idTypeId: String
    $numIdRecipient: String
    $additionalFields: String
  ) {
    quickSend(
      idRecipient: $idRecipient
      idCountryRecipient: $idCountryRecipient
      nameCountryRecipient: $nameCountryRecipient
      modePayCurrency: $modePayCurrency
      modePayCurrencyName: $modePayCurrencyName
      modePayReceiver: $modePayReceiver
      idPayer: $idPayer
      payerName: $payerName
      fnameRecipient: $fnameRecipient
      mnameRecipient: $mnameRecipient
      lnameRecipient: $lnameRecipient
      slnameRecipient: $slnameRecipient
      phoneRecipient: $phoneRecipient
      idCityRecipient: $idCityRecipient
      nameCityRecipient: $nameCityRecipient
      idStateRecipient: $idStateRecipient
      nameStateRecipient: $nameStateRecipient
      addressRecipient: $addressRecipient
      accountRecipient: $accountRecipient
      accountType: $accountType
      intendedUseOfFunds: $intendedUseOfFunds
      intendedUseOfFundsName: $intendedUseOfFundsName
      routingRecipient: $routingRecipient
      relationshipToSender: $relationshipToSender
      amountToSend: $amountToSend
      idTypeId: $idTypeId
      numIdRecipient: $numIdRecipient
      additionalFields: $additionalFields
    ) @client
  }
`;

export const UPDATE_RECIPIENT = gql`
  mutation updateCompleteRecipient(
    $fnameRecipient: String
    $mnameRecipient: String
    $lnameRecipient: String
    $slnameRecipient: String
    $idStateRecipient: String
    $idCityRecipient: String
    $addressRecipient: String
    $phoneRecipient: String
    $accountRecipient: String
    $routingRecipient: String
    $relationshipToSender: String
    $accountType: String
    $intendedUseOfFunds: String
    $intendedUseOfFundsName: String
    $nameStateRecipient: String
    $nameCityRecipient: String
    $numIdRecipient: String
    $typeIdRecipient: String
    $additionalFields: String
  ) {
    updateCompleteRecipient(
      fnameRecipient: $fnameRecipient
      mnameRecipient: $mnameRecipient
      lnameRecipient: $lnameRecipient
      slnameRecipient: $slnameRecipient
      idStateRecipient: $idStateRecipient
      idCityRecipient: $idCityRecipient
      addressRecipient: $addressRecipient
      phoneRecipient: $phoneRecipient
      accountRecipient: $accountRecipient
      routingRecipient: $routingRecipient
      relationshipToSender: $relationshipToSender
      accountType: $accountType
      intendedUseOfFunds: $intendedUseOfFunds
      intendedUseOfFundsName: $intendedUseOfFundsName
      nameStateRecipient: $nameStateRecipient
      nameCityRecipient: $nameCityRecipient
      numIdRecipient: $numIdRecipient
      typeIdRecipient: $typeIdRecipient
      additionalFields: $additionalFields
    ) @client
  }
`;

export const CREATE_TRANSACTION = gql`
  mutation CreateTransaction($idSender: String) @client {
    createTransaction(_id: $idSender)
  }
`;

export const TRANSACTION_COUNTRY = gql`
  query Transaction {
    transaction @client {
      idCountryRecipient
      modePayReceiver
      idRecipient
      idMainBranch
      accNumberRegex
      accountDescriptionEN
      # fnameRecipient
      # mnameRecipient
      # lnameRecipient
      # slnameRecipient
      # idStateRecipient
      # idCityRecipient
      # addressRecipient
      # phoneRecipient
    }
  }
`;

export const REVIEW_INFO = gql`
  query reviewInformation($jwt: String!) {
    transactionCost @client {
      amountToBePaid
      amountToSend
      exchangeRate
      fees
      currencyIsoCode
      currencySymbol
    }
    verifySender(jwt: $jwt) {
      _id
      fname
      lname
      mname
      slname
      email
      phone
      token
      idSender
      ssn
      hasSSN
      occupation
      cindustry
      sfunds
      cname
      caddress
      cphone
    }
    transaction @client {
      fnameRecipient
      mnameRecipient
      lnameRecipient
      slnameRecipient
      addressRecipient
      phoneRecipient
      emailRecipient
      idCountryRecipient
      idStateRecipient
      idCityRecipient
      amountToSend
      modePayReceiver
      idPayer
      accountRecipient
      modePayCurrency

      payerName
      modePayCurrencyName
      nameCountryRecipient

      nameStateRecipient
      nameCityRecipient

      relationshipToSender
      intendedUseOfFunds
      intendedUseOfFundsName

      accountType
      routingRecipient
      numIdRecipient
      typeIdRecipient
      additionalFields
      idMainBranch
    }
    occupation {
      id
      industryName
      order
      jobs {
        job
        idjob
      }
    }
    originFounds {
      originId
      originDescription
      order
    }
  }
`;

export const SEND_TRANSACTION = gql`
  mutation sendMoney(
    $idSender: String
    $idRecipient: String
    $fnameRecipient: String
    $mnameRecipient: String
    $lnameRecipient: String
    $slnameRecipient: String
    $idCountryRecipient: String
    $idStateRecipient: String
    $idCityRecipient: String
    $idPayer: String
    $addressRecipient: String
    $phoneRecipient: String
    $emailRecipient: String
    $zipRecipient: String
    $modePayReceiver: String
    $modePayCurrency: String
    $accountRecipient: String
    $routingRecipient: String
    $accountType: AccType
    $typeIdRecipient: String
    $numberIdRecipient: String
    $amountToSend: Float
    $idPayment: String
    $token: String!
    $totalCost: Float
    $ssn: String!
    $relationshipToSender: String!
    $intendedUseOfFunds: String!
    $intendedUseOfFundsName: String!
    $occupationName: String!
    $hasSSN: String!
    $cname: String!
    $caddress: String!
    $cphone: String!
    $cindustry: String!
    $sfunds: String
    $idMainBranch: String
  ) {
    sendMoney(
      idSender: $idSender
      idRecipient: $idRecipient
      fnameRecipient: $fnameRecipient
      mnameRecipient: $mnameRecipient
      lnameRecipient: $lnameRecipient
      slnameRecipient: $slnameRecipient
      idCountryRecipient: $idCountryRecipient
      idStateRecipient: $idStateRecipient
      idCityRecipient: $idCityRecipient
      idPayer: $idPayer
      addressRecipient: $addressRecipient
      phoneRecipient: $phoneRecipient
      emailRecipient: $emailRecipient
      zipRecipient: $zipRecipient
      modePayReceiver: $modePayReceiver
      modePayCurrency: $modePayCurrency
      accountRecipient: $accountRecipient
      routingRecipient: $routingRecipient
      accountType: $accountType
      typeIdRecipient: $typeIdRecipient
      numberIdRecipient: $numberIdRecipient
      amountToSend: $amountToSend
      idPayment: $idPayment
      token: $token
      totalCost: $totalCost
      ssn: $ssn
      relationshipToSender: $relationshipToSender
      intendedUseOfFunds: $intendedUseOfFunds
      intendedUseOfFundsName: $intendedUseOfFundsName
      occupationName: $occupationName
      hasSSN: $hasSSN
      cname: $cname
      caddress: $caddress
      cphone: $cphone
      cindustry: $cindustry
      sfunds: $sfunds
      idMainBranch: $idMainBranch
    ) {
      idReceiver
      fullNameRecipient
      fname
      amountToPaid
      amountToSend
      confirmed
      resultMessage
      folio
      transactionDate
      dateAvaliable
      requireAdditionalInformation
      holdMotives {
        hold
      }
    }
  }
`;

export default CREATE_SENDER;
