import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import gql from 'graphql-tag';
import Home from './components/home';
import Header from './components/header';
import SenderList from './components/senderList';
import SenderForm from './components/senderForm';
import ConfirmationForm from './components/confirmationForm';
import DestinationRates from './components/destinationRates';
import RecipientInfo from './components/recieverInfo';
import Receipt from './components/receipt';

import './App.css';

const client = new ApolloClient({
  uri: process?.env.REACT_APP_GRAPHQL_SERVER,
  request: operation => {
    const token = sessionStorage.getItem('token');
    operation.setContext({
      headers: {
        authorization: token
      }
    });
  },
  clientState: {
    defaults: {
      receipt: {
        folio: '',
        fname: '',
        fullNameRecipient: '',
        amountToPaid: '',
        amountToSend: '',
        confirmed: false,
        transactionDate: '',
        resultMessage: '',
        dateAvaliable: '',
        currencySymbol: '',
        currencyIsoCode: '',
        __typename: 'Receipt'
      },
      transactionCost: {
        amountToBePaid: 0,
        amountToSend: 0,
        exchangeRate: 0,
        fees: 0,
        hasCalculated: false,
        minMaxError: false,
        payerLimit: 0,
        amountToPaidOutLimit: false,
        currencyIsoCode: '',
        currencySymbol: '',
        __typename: 'TransactionCost'
      },
      transaction: {
        __typename: 'Transaction',
        idSender: '',
        idRecipient: '',
        fnameRecipient: '',
        mnameRecipient: '',
        lnameRecipient: '',
        slnameRecipient: '',

        idCountryRecipient: '',
        nameCountryRecipient: '',
        idStateRecipient: '',
        idCityRecipient: '',
        idPayer: '',

        addressRecipient: '',
        phoneRecipient: '',
        emailRecipient: '',
        zipRecipient: '',

        relationshipToSender: '',
        intendedUseOfFunds: '',
        intendedUseOfFundsName: '',

        modePayReceiver: '',
        accountRecipient: '',
        accountType: 'S',
        routingRecipient: '',
        modePayCurrency: '',
        typeIdRecipient: '',
        numberIdRecipient: '',
        amountToSend: '',
        totalCost: '',
        idPayment: '',

        payerName: '',
        modePayCurrencyName: '',
        modePayReceiverName: '',

        nameCityRecipient: '',
        nameStateRecipient: '',
        numIdRecipient: '',
        idMainBranch: '',
        accNumberRegex : '',
        additionalFields: []
      }
    },
    resolvers: {
      Mutation: {
        updateDestination: (
          _,
          {
            idPayer,
            modePayReceiver,
            idCountry,
            amountToSend,
            modePayCurrency,
            payerName,
            modePayCurrencyName,
            modePayReceiverName,
            nameCountryRecipient,
            addressRecipient,
            idStateRecipient,
            nameStateRecipient,
            idCityRecipient,
            nameCityRecipient,
            idMainBranch,
            accNumberRegex,
            accountDescriptionEN
          },
          { cache }
        ) => {
          const query = gql`
            query GetRecipient {
              transaction @client {
                modePayReceiver
                idCountryRecipient
                idPayer
                amountToSend
                modePayCurrency
                payerName
                modePayCurrencyName
                modePayReceiverName
                nameCountryRecipient
                idStateRecipient
                idCityRecipient
                nameStateRecipient
                nameCityRecipient
                idMainBranch
              }
            }
          `;
          const previousState = cache.readQuery({ query });
          const data = {
            ...previousState,
            transaction: {
              ...previousState.transaction,
              modePayReceiver,
              idPayer,
              idCountryRecipient: idCountry,
              amountToSend,
              modePayCurrency,
              payerName,
              modePayCurrencyName,
              modePayReceiverName,
              nameCountryRecipient,
              addressRecipient,
              idStateRecipient,
              nameStateRecipient,
              idCityRecipient,
              nameCityRecipient,
              idMainBranch,
              accNumberRegex,
              accountDescriptionEN
            }
          };
          cache.writeData({ query, data });
        },
        updateRecipient: (
          _,
          {
            idRecipient,
            fnameRecipient,
            mnameRecipient,
            lnameRecipient,
            slnameRecipient
          },
          { cache }
        ) => {
          const query = gql`
            query GetRecipient {
              transaction @client {
                idRecipient
                fnameRecipient
                mnameRecipient
                lnameRecipient
                slnameRecipient
              }
            }
          `;

          const previousState = cache.readQuery({ query });

          const data = {
            ...previousState,
            transaction: {
              ...previousState.transaction,
              idRecipient,
              fnameRecipient,
              mnameRecipient,
              lnameRecipient,
              slnameRecipient
            }
          };
          cache.writeData({ query, data });
          return null;
        },
        quickSend: (
          _,
          {
            idRecipient,
            idCountryRecipient,
            nameCountryRecipient,
            modePayCurrency,
            modePayCurrencyName,
            modePayReceiver,
            idPayer,
            payerName,
            fnameRecipient,
            mnameRecipient,
            lnameRecipient,
            slnameRecipient,
            phoneRecipient,
            idCityRecipient,
            nameCityRecipient,
            idStateRecipient,
            nameStateRecipient,
            addressRecipient,
            accountRecipient,
            accountType,
            relationshipToSender,
            intendedUseOfFunds,
            intendedUseOfFundsName,
            routingRecipient,
            amountToSend,
            numIdRecipient,
            additionalFields
          },
          { cache }
        ) => {
          const query = gql`
            query quickSendTransaction {
              transaction @client {
                idRecipient
                idCountryRecipient
                nameCountryRecipient
                modePayCurrency
                modePayCurrencyName
                modePayReceiver
                idPayer
                payerName
                fnameRecipient
                mnameRecipient
                lnameRecipient
                slnameRecipient
                phoneRecipient
                idCityRecipient
                nameCityRecipient
                idStateRecipient
                nameStateRecipient
                addressRecipient
                accountRecipient
                accountType
                relationshipToSender
                intendedUseOfFunds
                intendedUseOfFundsName
                routingRecipient
                amountToSend
                numIdRecipient
              }
              transactionCost @client {
                amountToBePaid
                amountToSend
                exchangeRate
                fees
                hasCalculated
                minMaxError
                payerLimit
                amountToPaidOutLimit
                currencyIsoCode
                currencySymbol
              }
            }
          `;

          const previousState = cache.readQuery({ query });
          const data = {
            ...previousState,
            transaction: {
              ...previousState.transaction,
              idRecipient,
              idCountryRecipient,
              nameCountryRecipient,
              modePayCurrency,
              modePayCurrencyName,
              modePayReceiver,
              idPayer,
              payerName,
              fnameRecipient,
              mnameRecipient,
              lnameRecipient,
              slnameRecipient,
              phoneRecipient,
              idCityRecipient,
              nameCityRecipient,
              idStateRecipient,
              nameStateRecipient,
              addressRecipient,
              accountRecipient,
              accountType,
              relationshipToSender,
              intendedUseOfFunds,
              intendedUseOfFundsName,
              routingRecipient,
              amountToSend,
              numIdRecipient,
              additionalFields
            },
            transactionCost: {
              ...previousState.transactionCost,
              amountToBePaid: 0,
              amountToSend: 0,
              exchangeRate: 0,
              fees: 0,
              hasCalculated: false,
              minMaxError: false,
              payerLimit: 0,
              amountToPaidOutLimit: false,
              currencyIsoCode: '',
              currencySymbol: '',
            }
          };
          cache.writeData({ query, data });
          return null;
        },
        updateCompleteRecipient: (
          _,
          {
            fnameRecipient,
            mnameRecipient,
            lnameRecipient,
            slnameRecipient,
            idStateRecipient,
            idCityRecipient,
            addressRecipient,
            phoneRecipient,
            accountRecipient,
            routingRecipient,
            accountType,
            relationshipToSender,
            intendedUseOfFunds,
            intendedUseOfFundsName,
            nameCityRecipient,
            nameStateRecipient,
            numIdRecipient,
            typeIdRecipient,
            additionalFields
          },
          { cache }
        ) => {
          const query = gql`
            query GetRecipient {
              transaction @client {
                fnameRecipient
                mnameRecipient
                lnameRecipient
                slnameRecipient
                idStateRecipient
                idCityRecipient
                addressRecipient
                phoneRecipient
                accountRecipient
                routingRecipient
                accountType
                relationshipToSender
                intendedUseOfFunds
                intendedUseOfFundsName
                nameStateRecipient
                nameCityRecipient
                typeIdRecipient
                numberIdRecipient
                additionalFields
              }
            }
          `;
          const previousState = cache.readQuery({ query });

          const data = {
            ...previousState,
            transaction: {
              ...previousState.transaction,
              fnameRecipient,
              mnameRecipient,
              lnameRecipient,
              slnameRecipient,
              idStateRecipient,
              idCityRecipient,
              addressRecipient,
              phoneRecipient,
              accountRecipient,
              routingRecipient,
              accountType,
              relationshipToSender,
              intendedUseOfFunds,
              intendedUseOfFundsName,
              nameStateRecipient,
              nameCityRecipient,
              numIdRecipient,
              typeIdRecipient,
              additionalFields
            }
          };
          cache.writeData({ query, data });
          return null;
        }
      }
    }
  }
});

const App = () => (
  <ApolloProvider client={client}>
    <Router>
      <div>
        <Route exact path="/verify/" component={Home} />
        <Route path="/sendmoney" component={Header} />
        <Route path="/sendmoney/new" component={SenderForm} />
        <Route path="/sendmoney/quicksend" component={SenderList} />
        <Route path="/sendmoney/destination" component={DestinationRates} />
        <Route path="/sendmoney/recipient" component={RecipientInfo} />
        <Route path="/sendmoney/confirmation" component={ConfirmationForm} />
        <Route path="/sendmoney/receipt" component={Receipt} />
      </div>
    </Router>
  </ApolloProvider>
);

export default App;
