import React from 'react';
import { Query } from 'react-apollo';
import queryString from 'query-string';
import { Redirect } from 'react-router-dom';
import VERIFY_SENDER from '../../graphql/verifySender';

const saveTokenToSession = token => {
  sessionStorage.setItem('token', token);
};

const Home = ({ location }) => {
  const values = queryString.parse(location.search);
  if (values) {
    saveTokenToSession(values.token);
  }

  return (
    <Query
      query={VERIFY_SENDER}
      variables={{ jwt: values.token || sessionStorage.getItem('token') }}
    >
      {({ loading, error, data }) => {
        if (loading) return 'Loading...';
        if (error) return `Error! ${error.message}`;
        if (!data.verifySender.idSender) {
          return <Redirect to={{ pathname: '/sendmoney/new' }} />;
        }

        return <Redirect to={{ pathname: '/sendmoney/quicksend' }} />;
      }}
    </Query>
  );
};

export default Home;
