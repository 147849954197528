import gql from 'graphql-tag';

const CREATE_SENDER = gql`
  mutation CreateSender(
    $fname: String!
    $mname: String
    $lname: String!
    $slname: String
    $phone: String!
    $address: String!
    $email: String!
    $birthdate: String!
    $token: String!
    $ssn: String!
    $sfunds: DropdownList
  ) {
    createSender(
      fname: $fname
      mname: $mname
      lname: $lname
      slname: $slname
      phone: $phone
      address: $address
      email: $email
      birthdate: $birthdate
      token: $token
      ssn: $ssn
      sfunds: $sfunds
    ) {
      _id
      idSender
      fname
      lname
      email
      hasSSN
      validationMessage
    }
  }
`;

export default CREATE_SENDER;
