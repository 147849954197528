import React, { useState } from 'react';

import { Query, Mutation } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { Pane, Heading, Text, Alert } from 'evergreen-ui';
import { Spinner } from 'evergreen-ui/commonjs/spinner';
import { VERIFY_SENDER_EXTENDED } from '../../graphql/verifySender';
import CREATE_SENDER from '../../graphql/createSender';
import SenderDataForm from './senderDataForm';

import './style.css';

const SenderForm = ({ history }) => {
  const [validationError, SetValidationError] = useState('');
  return (<Pane justifyContent="center" display="flex">
    <div className="sender-form-container">
      <div className="sender-form-title">
        <Heading size={700} marginTop="default">
          UPDATE YOUR PERSONAL INFORMATION
        </Heading>
        <Text>
          Please review and update your personal information so it appears
          exactly as it is displayed on your passport.
        </Text>
        <br />
        {validationError && (
        <div className="form-element-container">
          <Alert
          intent="warning"
          title={validationError}
          marginTop={32}
          />
          {validationError.includes("Birthdate format") &&
          (<a href="mailto:Support@cardmarte.com">Please contact to update your info.</a>)}          
        </div>
        )}
      </div>
      <Mutation mutation={CREATE_SENDER}>
        {(createSender, info) => 
          <Query
            query={VERIFY_SENDER_EXTENDED}
            variables={{ jwt: sessionStorage.getItem('token') }}
          >
            {({ loading, error, data }) => {                 
              if (loading) return <Text><Spinner /> Loading...</Text>;
              if (error) return <Text>Error:{error.message} </Text>;
              const submitInfo = values => {
                localStorage.setItem('senderSSN', values.ssnnone ? "" : values.ssn);
                return createSender({
                  variables: {
                    fname: values.fname,
                    mname: values.mname,
                    lname: values.lname,
                    slname: values.slname,
                    phone: values.phone,
                    countryCode: values.countryCode,
                    address: values.address,
                    email: values.email,
                    token: values.token,
                    birthdate: values.birthdate,
                    occupation: values.occupation,
                    sfunds: values.sfunds,
                    ssn: values.ssnnone ? "" : values.ssn,
                    countryIssue: values.countryIssue,
                    hasSSN: values.ssnnone ? "false" : "true"
                  }
                }).then(response => {
                  console.log("Create sender response", response);
                  if(response.data.createSender.idSender === null)
                    {
                      console.log("Sender was not created");
                      SetValidationError(response.data.createSender.validationMessage);
                    }
                  else
                    history.push('/sendmoney/quicksend');
                });
              }
              return (
                <div
                  className="sender-form"
                  data-testid="sender-form-component"
                >
                  <SenderDataForm
                    sender={data}
                    updateUser={submitInfo}
                    loading={info.loading}
                  />
                </div>
              );
            }}
          </Query>
        }
      </Mutation>
    </div>
  </Pane>
)};

export default withRouter(SenderForm);
