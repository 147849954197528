/* eslint-disable no-plusplus */
import React from 'react';
import { Query, graphql, compose } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { string, object, mixed } from 'yup';
import { Pane, Heading, Text } from 'evergreen-ui';
import RecipientInfoForm from './recipientInfoForm';
import { COUNTRY_INFO } from '../../graphql/countries';
import {
  TRANSACTION_COUNTRY,
  UPDATE_RECIPIENT
} from '../../graphql/transaction';


export const getValidationSchema = (modePayReceiver, idCountryRecipient, accNumberRegex, accountDescriptionEN) =>
  object().shape({
    fnameRecipient: string()
      .required('First name is required')
      .min(1, 'Enter a valid name')
      .matches(/^\w+( \w+)*$/, {
        message: 'Not a valid value!'
      })
      .matches(/^[a-zA-Z ]+$/, {
        message: 'Numbers are not allowed'
      })
      .matches(/^(?!no$|(first|last)$|no(\s)?(first|last)|name$|(first|last)(\s)?name|no(\s)?(first|last)?name).*/i, {
        message: 'These texts are not allowed'
      }),
    mnameRecipient: string()
      .min(1, 'Enter a valid name')
      .matches(/^\w+( \w+)*$/, {
        message: 'Not a valid value!'
      })
      .matches(/^[a-zA-Z ]+$/, {
        message: 'Numbers are not allowed'
      }),
    lnameRecipient: string()
      .required('Last name is required')
      .min(1, 'Enter a valid name')
      .matches(/^\w+( \w+)*$/, {
        message: 'Not a valid value!'
      })
      .matches(/^[a-zA-Z ]+$/, {
        message: 'Numbers are not allowed'
      })
      .matches(/^(?!no$|(first|last)$|no(\s)?(first|last)|name$|(first|last)(\s)?name|no(\s)?(first|last)?name).*/i, {
        message: 'These texts are not allowed'
      }),
    slnameRecipient: string()
      .min(1, 'Enter a valid name')
      .matches(/^\w+( \w+)*$/, {
        message: 'Not a valid value!'
      })
      .matches(/^[a-zA-Z ]+$/, {
        message: 'Numbers are not allowed'
      }),
    addressRecipient: string().required('Recipient address is required'),
    phoneRecipient: string()
      .matches(/^[0-9]*$/, 'Please enter a valid phone number')
      .min(7, 'Phone number should have minimum 7 digits')
      .max(12, 'Phone number cannot have more than 12 digits')
      .required('Phone number is required'),
    accountRecipient:
      modePayReceiver === 'C'
        ? string()
            .matches(accNumberRegex, accountDescriptionEN)
            .min(4, 'Account number should have minimum 4 digits')
            .max(30, 'Account number cannot have more than 30 digits')
            .required('Please enter a valid account number')
        : mixed().notRequired(),
    accountType:
      modePayReceiver === 'C'
        ? object().shape({
            label: string().required('Please enter a valid account type')
          })
        : mixed().notRequired(),
    relationshipToSender: string().required('Relationship to sender is required'),
    intendedUseOfFunds:
      object().shape({
        label: string().required('Please enter a intented use of funds')
      })
  });

export const RecieverForm = ({
  idCountryRecipient,
  updateRecipient,
  history,
  modePayReceiver,
  idMainBranch,
  accNumberRegex,
  accountDescriptionEN
}) => (
  <Query query={COUNTRY_INFO}>
    {({ loading, error, data }) => {
      // const ld = info.loading;
      // const dt = info.data;
      // const err = info.error;
      if (loading) return 'Loading...';
      if (error) return `Error! ${error.message}`;
      return (
        <Pane
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <RecipientInfoForm
            onSubmit={values => {
              console.log("RecipientInfoForm onSubmit", values);
              const {additionalFields} = values;
              let routingRecipient = '';
              let numIdReceiver = '';
              let typeIdReceiver = '';
              let {addressRecipient} = values;
              let accountReceiver = '';

              if (additionalFields !== undefined && additionalFields.length > 0) {
                const direccion = additionalFields.find(
                  (a) => a.field === 'ADDRESS_RECEIVER'
                );
                
                if (direccion !== undefined && direccion !== null)
                  addressRecipient = direccion.value || values.addressRecipient;
                
                const account = additionalFields.find(
                  (a) => a.field === 'ACC_RECEIVER'
                );
                if (account !== undefined && account !== null)
                  accountReceiver = account.value;
        
                const routing = additionalFields.find(
                  (a) => a.field === 'BANK_RECEIVER'
                );                
                if (routing !== undefined && routing !== null)
                  routingRecipient = routing.value;

                const idNumber = additionalFields.find((a) => a.field === 'NUMID');
                if (idNumber !== undefined && idNumber !== null) {
                  numIdReceiver = idNumber.value;
                  if (idCountryRecipient === 'BRA') typeIdReceiver = 'BF';
                }

                const typeId = additionalFields.find((a) => a.field === 'TYPEID');
                if (typeId !== undefined && typeId !== null)
                  typeIdReceiver = typeId.value;
              }
              updateRecipient({
                variables: {
                  fnameRecipient: values.fnameRecipient,
                  mnameRecipient: values.mnameRecipient,
                  lnameRecipient: values.lnameRecipient,
                  slnameRecipient: values.slnameRecipient,
                  idStateRecipient: values.idStateRecipient.value,
                  idCityRecipient: values.idCityRecipient.value,
                  addressRecipient,
                  phoneRecipient: values.phoneRecipient,
                  accountRecipient: accountReceiver || values.accountRecipient,
                  accountType: values.accountType.value,
                  intendedUseOfFunds: values.intendedUseOfFunds.value,
                  intendedUseOfFundsName: values.intendedUseOfFunds.label,
                  routingRecipient,
                  relationshipToSender: values.relationshipToSender,
                  nameStateRecipient: values.idStateRecipient.label,
                  nameCityRecipient: values.idCityRecipient.label,
                  numIdRecipient: numIdReceiver,
                  typeIdRecipient: typeIdReceiver,
                  additionalFields: JSON.stringify(additionalFields)
                }
              }).then(() => {
                history.push('/sendmoney/confirmation');
              });
            }}
            backButton={() => {sessionStorage.removeItem('additionalFields'); history.goBack();}}
            data={data}
            defaultData={data.transaction}
            validationSchema={getValidationSchema(
              modePayReceiver,
              idCountryRecipient,
              accNumberRegex,
              accountDescriptionEN
            )}
            modePayReceiver={modePayReceiver}
            idMainBranch={idMainBranch}
            idCountryRecipient={idCountryRecipient}
          />
        </Pane>
      );
    }}
  </Query>
);

const RecieverInfo = props => {
  const { updateRecipient, history } = props;
  return (
    <Pane
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
    >
      <Heading size={700} marginTop="default">
        RECIPIENT INFORMATION
      </Heading>
      <Text marginBottom="20px" textAlign="center" padding={10}>
        Recipient information must match their government issued IDs or payout
        may be refused.
      </Text>
      <Query query={TRANSACTION_COUNTRY}>
        {({ loading, error, data }) => {
          if (loading) return 'Loading...';
          if (error) return `Error! ${error.message}`;

          const { modePayReceiver, idCountryRecipient, idMainBranch, accNumberRegex, additionalFields } = data.transaction;
          let { accountDescriptionEN } = data.transaction;
          
          if(accountDescriptionEN === null || accountDescriptionEN.length === 0)
            accountDescriptionEN = `The provided account number does not correspond to format required by the payer`;
          
          console.log("idMainBranch", idMainBranch, "accountDescriptionEN", accountDescriptionEN, "accNumberRegex", accNumberRegex, "additionalFields", additionalFields);
          return (
            <RecieverForm
              updateRecipient={updateRecipient}
              history={history}
              modePayReceiver={modePayReceiver}
              idCountryRecipient={idCountryRecipient}
              idMainBranch={idMainBranch}
              accNumberRegex={new RegExp(accNumberRegex)}
              accountDescriptionEN={accountDescriptionEN}
            />
          );
        }}
      </Query>
    </Pane>
  );
};

export default compose(graphql(UPDATE_RECIPIENT, { name: 'updateRecipient' }))(
  withRouter(RecieverInfo)
);
