import gql from 'graphql-tag';

const GET_RECEIPT = gql`
  query Receipt {
    receipt @client {
      folio
      fname
      fullNameRecipient
      amountToPaid
      amountToSend
      confirmed
      transactionDate
      resultMessage
      dateAvaliable
      currencySymbol
      currencyIsoCode
    }
  }
`;

export default GET_RECEIPT;
