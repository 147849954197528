import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { TextInput, Button, Heading, Pane, Text } from 'evergreen-ui';
import LocationSearchInput from "@viamericas/location-search-input";
import './style.css';
import SelectControl from '../selectControl';

const queryPaymentLocation = JSON.stringify({
  query: `{
    paymentLocations(
      cityId: "@cityId"
      stateId: "@stateId"
      countryId: "@countryId"
      paymentMode: "@paymentMethod"
      currency: "@currency"
    ) {
      id
      paymentLocationCode
      paymentLocationName
      countryId
      countryName
      cityId
      stateId
      currencyName
      payerName
      dolarLimit
      nationalLimit
      networkPayment
      addressBranch
      processedByCode
      accNumberRegex
      accountDescriptionEN
      accountLabelEN
    }
  }`,
});

const queryDeliveryMethods = JSON.stringify({
  query: `{
    deliveryMethods (
      countryId: "@countryId"
    ) {
      countryId
      currencies {
        id
        iso
        name
        paymentMethods {
          value
          name
        }
      }
    }
  }`,
});


export const getCurrencies = (countries, selectedCountry) => {
  if (selectedCountry.value === '') return [];
  const validateCurrencies = countries.find(country => country.countryId === selectedCountry.value);
  if (!validateCurrencies) {
    return [];
  }

  const { currencies } = validateCurrencies
  return currencies.filter(c => c.name !== null);
};

export const getPaymentMethods = (
  countries,
  selectedCurrency,
  selectedCountry
) => {  
  if (selectedCurrency.value === '') return [];
  return getCurrencies(countries, selectedCountry)?.find(
    currency => currency.id === selectedCurrency.value
  )?.paymentMethods ?? [];
};

const ENV = process.env;
const DestinationRatesForm = props => {
  const [countries, findDeliveryMethods] = useState([{}]);
  const { defaultData } = props;
  const [defaultAddress, setDefaultAddress] = useState(defaultData.addressRecipient);

  const [selectedState, changeState] = useState(defaultData.idStateRecipient);
  const [selectedCity, changeCity] = useState(defaultData.idCityRecipient);

  const [selectedCountry, changeCountry] = useState({
    label: defaultData.nameCountryRecipient,
    value: defaultData.idCountryRecipient
  });
  const [selectedCurrency, changeCurrency] = useState({
    label: defaultData.modePayCurrencyName,
    value: defaultData.modePayCurrency
  });
  const [selectedPaymentMethod, changePaymentMethod] = useState({
    label: (() => {
            switch(defaultData.modePayReceiver) {
              case 'P': return 'CASH PICKUP';
              case 'C': return 'BANK DEPOSIT';
              case 'O': return 'MOBILE WALLET';
              case 'D': return 'HOME DELIVERY';
              default: return '';
            }
          })(),
    value: defaultData.modePayReceiver
  });
  const [selectedPayer, changePayer] = useState({
    label: defaultData.payerName,
    value: defaultData.idPayer,
    nationalLimit: 0,
    dolarLimit: 0
  });

  const {
    validationSchema,
    data: { transactionCost },
    isLoading,
    onSubmit,
    onNext
  } = props;

  const [dolarLimit, setDolarLimit] = useState(0);
  const [nationalLimit, setNationalLimit] = useState(0);
  const [idMainBranch, setIdMainBranch] = useState('0');
  const [accNumberRegex, setAccNumberRegex] = useState('');
  const [accountDescriptionEN, setAccountDescriptionEn] = useState('');

  const handleChangeCosts = () => {
    if (selectedCountry.value !== '' && selectedCurrency.value !== '' &&
      selectedPaymentMethod.value !== '' && selectedPayer.value !== '')
      return true;

    return false;
  };

  const [selectedChange, changeCosts] = useState(handleChangeCosts());

  const [paymentLocationOptions, findPaymetLocations] = useState([{
    label: defaultData.payerName,
    value: defaultData.idPayer
  }]);
  
  const [amountDisabled, setAmountDisabled] = useState(!!defaultData.idPayer);

  const [disabledByCountryWithNoCurrency, setDisabledByCountryWithNoCurrency] = useState(false);

  useEffect(() => {
    let isSubscribed = true;
    const LoadPaymentLocations = async () => {
      let result = [];
      if (selectedPaymentMethod.value !== '') {
        let bodyPaymentLocation = queryPaymentLocation;
        bodyPaymentLocation = bodyPaymentLocation.replace("@cityId", selectedCity || "");
        bodyPaymentLocation = bodyPaymentLocation.replace("@stateId", selectedState || "");
        bodyPaymentLocation = bodyPaymentLocation.replace("@countryId", selectedCountry.value || "");
        bodyPaymentLocation = bodyPaymentLocation.replace("@currency", selectedCurrency.value || "");
        bodyPaymentLocation = bodyPaymentLocation.replace("@paymentMethod", selectedPaymentMethod.value || "");
        const response = await fetch(
          process.env.REACT_APP_GRAPHQL_SERVER,
          {
            method: 'post',
            body: bodyPaymentLocation,
            headers: {
              'Content-Type': 'application/json',
              'Content-Length': bodyPaymentLocation.length
            },
          }
        );
        const jsonResponse = await response.json();
        const uniqueResponse = [... new Map(jsonResponse.data.paymentLocations.map(pay => [pay?.payerName, pay])).values() ];
        // eslint-disable-next-line no-nested-ternary
        result = uniqueResponse.map(info => ({ label: info.networkPayment === "N" ? info.countryId === 'URU' ? `${info.payerName} - ${info.paymentLocationName}: ${info.addressBranch}` : `${info.payerName} - ${info.paymentLocationName}` : info.payerName, value: info.paymentLocationCode, nationalLimit: info.nationalLimit, dolarLimit: info.dolarLimit, idMainBranch: info.processedByCode, accNumberRegex: info.accNumberRegex, accountDescriptionEN: info.accountDescriptionEN, accountLabelEN: info.accountLabelEN }));
        result = result.length === 0 ? [({ label: 'None', value: '0' })] : result.sort((p1, p2) => p1.label > p2.label ? 1 : -1);
      }

      // set state with the result if `isSubscribed` is true
      if (isSubscribed) {
        findPaymetLocations(result);
        if (result.some(r => r.value === selectedPayer.value)) {
          const payer = result.find(r => r.value === selectedPayer.value);
          console.log("Payer found:", payer);
          changePayer(payer);
          setDolarLimit(payer.dolarLimit);
          setNationalLimit(payer.nationalLimit);
          setIdMainBranch(payer.idMainBranch);
          setAccNumberRegex(payer.accNumberRegex);
          setAccountDescriptionEn(payer.accountDescriptionEN);
          setAmountDisabled(false);
        }
      }
    };

    // call the function
    LoadPaymentLocations()
      // make sure to catch any error
      .catch(console.error);

    // cancel any future invocation
    return () => { isSubscribed = false };
  }, [selectedCity, selectedState, selectedCountry, selectedCurrency, selectedPaymentMethod]);

  useEffect(() => {
    let isSubscribed = true;
    const LoadDeliveryMethods = async () => {
      let result = [];
      if (selectedCountry.value !== '') {
        let bodyPaymentLocation = queryDeliveryMethods;
        bodyPaymentLocation = bodyPaymentLocation.replace("@countryId", selectedCountry.value || "");
        
        const response = await fetch(
          process.env.REACT_APP_GRAPHQL_SERVER,
          {
            method: 'post',
            body: bodyPaymentLocation,
            headers: {
              'Content-Type': 'application/json',
              'Content-Length': bodyPaymentLocation.length
            },
          }
        );
        const jsonResponse = await response.json();

        result = jsonResponse.data.deliveryMethods;
      }

      if (isSubscribed) {
        findDeliveryMethods(result);
        setAmountDisabled(false);
        if (result?.length > 0 && result[0]?.currencies?.length > 0) setDisabledByCountryWithNoCurrency(false);
        else setDisabledByCountryWithNoCurrency(true);
      }
    };

    // call the function
    LoadDeliveryMethods()
      // make sure to catch any error
      .catch(console.error);

    // cancel any future invocation
    return () => { isSubscribed = false };
  }, [selectedCountry]);

  return (
    <div className="destination-rate-form-container">
      <Formik
        initialValues={{
          amount: defaultData.amountToSend,
          location: defaultData.addressRecipient,
          selectedCountry,
          selectedCurrency,
          selectedPaymentMethod,
          selectedPayer,
          idCityRecipient: defaultData.idCityRecipient,
          idStateRecipient: defaultData.idStateRecipient,
          nameStateRecipient: defaultData.nameStateRecipient,
          nameCityRecipient: defaultData.nameCityRecipient,
          dolarLimitAmount: dolarLimit,
          idMainBranch,
          accNumberRegex,
          accountDescriptionEN
        }}
        onSubmit={values => {
          console.log("Values", values);
          onSubmit(values);
        }}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, errors }) => {

          const resetCalculateCosts = () => {
            transactionCost.amountToBePaid = 0;
            transactionCost.exchangeRate = 0;
            transactionCost.fees = 0;
            transactionCost.amountToPaidOutLimit = false;
            transactionCost.currencyIsoCode = '';
            transactionCost.currencySymbol = '';
            transactionCost.hasCalculated = false;
            transactionCost.amountToSend = 0;
          };

          const handleSuccessLocation = e => {
            const locationFound = e.location?.toUpperCase() || ''; 
            setFieldValue('location', locationFound); 
            setDefaultAddress(locationFound); 
            changeCountry({
              label: e.countryName || '',
              value: e.countryId || ''
            }); 

            setFieldValue('selectedCountry', {
              label: e.countryName || '',
              value: e.countryId || ''
            });
 
            changeCurrency({ label: '', value: '' });
            changePaymentMethod({ label: '', value: '' });
            changePayer({ label: '', value: '' });
            setFieldValue('selectedCurrency', '');
            setFieldValue('selectedPaymentMethod', '');
            setFieldValue('selectedPayer', '');
            setFieldValue('idStateRecipient', e.subDivisionCode);
            changeState(e.subDivisionCode);
            setFieldValue('nameStateRecipient', e.subDivisionName);
            setFieldValue('nameCityRecipient', e.cityName);
            setFieldValue('idCityRecipient', e.cityId);

            changeCity(e.cityId);
            resetCalculateCosts();
            changeCosts(false);  
          };

          const handleClose = e => {
            changeCountry({
              label: '',
              value: ''
            });
            setFieldValue('selectedCountry', {
              label: '',
              value: ''
            });
            setFieldValue('location', '');
            setDefaultAddress('');
            changeCurrency({ label: '', value: '' });
            changePaymentMethod({ label: '', value: '' });
            changePayer({ label: '', value: '' });
            setFieldValue('selectedCurrency', '');
            setFieldValue('selectedPaymentMethod', '');
            setFieldValue('selectedPayer', '');
            changeState('');
            changeCity('');
            document.getElementById('beneficiaryLocationSearchInput').focus();
            setFieldValue('nameCityRecipient', '');
            setFieldValue('idCityRecipient', '');
            setFieldValue('nameStateRecipient', '');
            setFieldValue('idStateRecipient', '');
            resetCalculateCosts();
            changeCosts(false);
            setDisabledByCountryWithNoCurrency(false)

          };

         

          const config = {
            url: ENV.REACT_APP_GRAPHQL_SERVER.replace('/graphql', ''),
            token: null
          };

          const ux = {
            classNameSearchApi: 'search-api',
            classNameSearchBarContainer: 'search-bar-container',
            classNameSearchInputContainer: 'bank-search',
            classNameSearchInput: 'form-control font-weight-bold bank-search__input2 css-5ljhhe',
            classNameSearchButton: 'search-button',
            classNameAutoCompleteContainer: 'bank-search__results4 overflow-auto',
            classNameSuggestionItem: 'bank-search__bank',
            classNameLoading: 'fa fa-spinner fa-pulse fa-3x fa-fw spinner'
          };
          return (
            <Form autoComplete='off'>
              <div data-testid="destination-rates-form" className='location-container'>
                <div className="form-element-container">
                  <Text>Destination city state and country</Text>
                  <LocationSearchInput
                    id="beneficiaryLocationSearchInput"
                    name="location"
                    handleSuccess={handleSuccessLocation}
                    handleClose={handleClose}
                    handleError={handleClose}
                    config={config}
                    placeholder="CITY  STATE  COUNTRY. Example: MIAMI  FLORIDA  UNITED STATES"
                    language="en"
                    languageURL=""
                    onKeyUp={e => {
                      // onKeyUpLocation(e);
                      setDisabledByCountryWithNoCurrency(false)
                    }} 
                    ux={ux}
                    addressDefault={defaultAddress}
                  />
                  {errors.location && (
                    <span className="location-validation">
                      {errors.location}
                    </span>
                  )}

                  {(selectedCountry.value !== '' && disabledByCountryWithNoCurrency) && (
                    <span className="location-validation">
                      The selected country is not available
                    </span>
                  )}
                </div>
              </div>
              <div data-testid="destination-rates-form" className='location-container'>
                <div className="form-element-container">
                  <SelectControl
                    controlTitle="Currency"
                    data={getCurrencies(countries, selectedCountry).map(
                      ({ name, id }) => ({
                        label: name,
                        value: id
                      })
                    )}
                    onChange={selected => {
                      changeCurrency({
                        label: (selected && selected.label) || '',
                        value: (selected && selected.value) || ''
                      });
                      changePaymentMethod({ label: '', value: '' });
                      changePayer({ label: '', value: '' });
                      setFieldValue('selectedCurrency', {
                        label: (selected && selected.label) || '',
                        value: (selected && selected.value) || ''
                      });
                      setFieldValue('selectedPaymentMethod', '');
                      setFieldValue('selectedPayer', '');
                      changeCosts(false);
                    }}
                    selectedItem={selectedCurrency}
                    error={errors.selectedCurrency}
                    defaultLabel="Select a currency"
                    disabled={disabledByCountryWithNoCurrency}

                  />
                </div>
                <div className="form-element-container">
                  <SelectControl
                    controlTitle="Payment Method"
                    data={getPaymentMethods(
                      countries,
                      selectedCurrency,
                      selectedCountry
                    ).map(({ name, value }) => ({
                      label: name,
                      value
                    }))}
                    onChange={selected => {
                      changePayer({ label: '', value: '' });
                      changePaymentMethod({
                        label: (selected && selected.label) || '',
                        value: (selected && selected.value) || ''
                      });
                      setFieldValue('selectedPaymentMethod', {
                        label: (selected && selected.label) || '',
                        value: (selected && selected.value) || ''
                      });
                      setFieldValue('selectedPayer', '');
                      changeCosts(false);
                    }}
                    selectedItem={selectedPaymentMethod}
                    error={errors.selectedPaymentMethod}
                    defaultLabel="Select a payment method"
                    disabled={disabledByCountryWithNoCurrency}
                  />
                </div>
              </div>
              <div data-testid="destination-rates-form" className='location-container'>
                <div className="form-element-container">
                  <SelectControl
                    controlTitle="Recipient Bank / Payer"
                    data={paymentLocationOptions}
                    onChange={selected => {
                      if (selected.value !== '0') {
                        console.log("Payer selected", selected);
                        setDolarLimit(selected.dolarLimit);
                        setNationalLimit(selected.nationalLimit);
                        setFieldValue('dolarLimitAmount', selected.dolarLimit);
                        setFieldValue('idMainBranch', selected.idMainBranch);
                        setFieldValue('accNumberRegex', selected.accNumberRegex);
                        setFieldValue('accountDescriptionEN', selected.accountDescriptionEN)
                        setIdMainBranch(selected.idMainBranch);
                        changePayer({
                          label: (selected && selected.label) || '',
                          value: (selected && selected.value) || '',
                          nationalLimit: (selected && selected.nationalLimit) || 0,
                          dolarLimit: (selected && selected.dolarLimit) || 0
                        });
                        setFieldValue('selectedPayer', {
                          label: (selected && selected.label) || '',
                          value: (selected && selected.value) || ''
                        });
                        resetCalculateCosts();
                      }
                    }}
                    selectedItem={selectedPayer}
                    error={errors.selectedPayer}
                    defaultLabel="Select a payer"
                    useClass="w_580px"
                    width={500}
                    disabled={disabledByCountryWithNoCurrency}

                  />
                </div>
              </div>
              <div className="amount-container">
                <div className="form-element-container">
                  <Text>Amount to be sent (USD) {amountDisabled ? '... wait to map payer' : ''}</Text>
                  <TextInput
                    name="amount"
                    value={values.amount}
                    onChange={(e) => {
                      setFieldValue('amount', e.target.value);
                      setFieldValue('dolarLimitAmount', dolarLimit);
                      setFieldValue('idMainBranch', idMainBranch);
                      resetCalculateCosts();
                      changeCosts(false);
                    }}
                    placeholder="Amount"
                    type="number"
                    step="0.01"
                    disabled={amountDisabled || disabledByCountryWithNoCurrency}
                  />
                  <Text size={300} color="#EC4C47">
                    {errors.amount}
                  </Text>

                  {transactionCost.minMaxError ? (
                    <Text size={300} color="#EC4C47">
                      Amount either, exceeded or not enough for transaction
                    </Text>
                  ) : null}
                </div>
              </div>
              <div className="form-submit-btn-container">
                <Button type="submit" isLoading={isLoading} onClick={() => changeCosts(handleChangeCosts())}
                  disabled={disabledByCountryWithNoCurrency}
                >
                  {isLoading ? 'Processing...' : 'CALCULATE FEES & FX'}{' '}
                </Button>
              </div>

              <div className="form-payment-info-container">
                <div className="form-payment-info">
                  <Pane
                    width="200px"
                    height="100px"
                    background="tint2"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin="20px"
                  >
                    <Heading size={100}>Amount to be paid</Heading>
                    <Text size={600}>
                      {transactionCost.currencySymbol} {transactionCost.amountToBePaid} {transactionCost.currencyIsoCode}
                    </Text>
                    {nationalLimit > 0 && transactionCost.amountToBePaid >= nationalLimit ? (
                      <Text size={300} color="#EC4C47">
                        Amount exceeds: {nationalLimit}
                      </Text>
                    ) : null}
                  </Pane>
                  <Pane
                    width="250px"
                    height="100px"
                    background="tint2"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin="20px"
                  >
                    <Heading size={100}>Exchange Rate</Heading>
                    <Text size={600}>{`1 USD = ${transactionCost.exchangeRate
                      }`}</Text>
                  </Pane>
                  <Pane
                    width="200px"
                    height="100px"
                    background="tint2"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    margin="20px"
                  >
                    <Heading size={100}>Fees</Heading>
                    <Text size={600}>{`$${transactionCost.fees
                      } USD`}</Text>
                  </Pane>
                </div>
              </div>
              {transactionCost.hasCalculated
                && ((nationalLimit > 0 && transactionCost.payerLimit === nationalLimit && !transactionCost.amountToPaidOutLimit)
                  || (dolarLimit > 0 && transactionCost.amountToSend < dolarLimit)
                  || (dolarLimit === 0 && nationalLimit === 0))
                && selectedChange ? (
                <div className="form-submit-btn-container">
                  <Button onClick={onNext}>NEXT</Button>
                </div>
              ) : null}
            </Form>
          )
        }}
      </Formik>
    </div>
  );
};

export default DestinationRatesForm;